@import "src/assets/styles/_variables.module.scss";

.chatContainer {
  @media (max-width: 1365px) {
    width: 420px;
  }
}

.chatsArea {
  padding: 20px 30px 20px 30px;
  background-color: #191a2dcc;
  border-radius: 5px 5px 0 0;
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 466px);

  @media (max-height: 960px) {
    height: 580px;
  }

  .messages {
    @include scrollBar;
    width: 100%;
    padding: 0 30px;
    overflow-y: auto;
    list-style: none;

    .messageInfo {
      margin-bottom: 20px;

      .name {
        font-family: Noto Sans JP;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.1em;
        text-align: left;
        color: #ffffff80;
      }

      .message {
        font-family: Noto Sans JP;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1em;
        text-align: left;
        color: #ffffff;
      }
    }
  }

  .inputWithButton {
    height: 40px;
  }
}

.chatModeSwitcher {
  @include fMiniBoldSaira;
  width: 420px;
  height: 30px;
  display: flex;

  .lobbyChat {
    width: 230px;
    clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
    border-radius: 0 0 0 5px;
    cursor: pointer;
    color: #ffffff;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .teamChat {
    width: 230px;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%);
    margin-left: -35px;
    border-radius: 0 0 5px 0;
    cursor: pointer;
    color: #ffffff;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .selected {
    background-color: #533eae;
  }

  .unSelected {
    background-color: #ffffff4d;
  }
}

.voiceChat {
  height: 110px;
  width: 420px;
  background-color: #191a2dcc;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;

  .mic {
    font-size: 30px;
  }

  .headphone {
    font-size: 30px;
  }

  .settings {
    font-size: 20px;
    position: relative;
    top: 40px;
    right: 5px;
    color: #ffffff;
    cursor: pointer;
  }
}

.callEnd {
  color: #ff9b9b;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  color: #ffffff;
  background-color: #c34343;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.mic {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  color: #533eae;
  background-color: #ffffff;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.headphone {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  color: #533eae;
  background-color: #ffffff;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.disabled {
  color: #ffffff;
  background-color: #b8b8b8;
}

.voiceMenu {
  margin-left: 20px;
  width: 230px;
}

.voiceChatMembers {
  .voiceChatMemberInfo {
    margin-bottom: 7px;
    display: flex;
    align-items: center;

    .audio {
      display: none;
    }

    .chatting {
      font-size: 20px;
      color: #ffffff;
    }

    .speaking {
      color: #7ecc60;
    }

    .userName {
      font-family: Noto Sans JP;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.1em;
      text-align: left;
      color: #aeb1cc;
      margin-left: 10px;
    }
  }
}

.buttomButtons {
  display: flex;
  position: relative;
  left: -60px;
  top: 18px;
  color: white;
  width: 360px;
  bottom: 20px;
  justify-content: space-between;

  .leaveRoom {
    width: 160px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .kickPlayers {
    width: 160px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

.hide {
  display: none;
}
