@import "src/assets/styles/_variables.module.scss";

.voiceChatMemberInfo {
  margin-bottom: 7px;
  display: flex;
  align-items: center;

  .audio {
    display: none;
  }

  .chatting {
    font-size: 20px;
    color: #ffffff;
  }

  .speaking {
    color: #7ecc60;
  }

  .userName {
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #aeb1cc;
    margin-left: 10px;
  }
}
