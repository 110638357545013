@import "src/assets/styles/_variables.module.scss";

.header {
  display: flex;
  position: fixed;
  right: 20px;
  align-self: center;

  .unSigned {
    @include jpParagraph03;
    width: 140px;
    height: 40px;
    border-radius: 24px;
    background-color: #ffffff;
    color: #533eae;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.subMenu {
  width: 350px;
  display: flex;
  justify-content: center;

  .unSigned {
    @include jpParagraph03;
    width: 270px;
    height: 48px;
    border-radius: 24px;
    background-color: #ffffff;
    color: #533eae;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.signed {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  color: #533eae;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
}

.loginContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.backGroundShadow {
  background-color: #38336680;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  min-height: 960px;
  min-width: 640px;
}

.loginModal {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 522px;
  height: 320px;
  padding: 40px 48px;
  background-color: #423d77;
  border-radius: 10px;

  .title {
    @include jpHeadline01;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #ffffff;
  }

  .subTitle {
    @include jpParagraph02;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    color: #ffffff;
  }

  .socialService {
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
  }

  .googleLogin {
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 160px;
  }

  .steamLogin {
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 160px;
  }

  .serviceIcon {
    width: 60px;
    height: 60px;
  }

  .description {
    @include fRegularNotoSansJP400;
    color: #ffffff;
    margin-top: 20px;
  }
}

.userInfoContainer {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 350px;
  padding: 0 40px;
  display: flex;
  flex-direction: column;

  .signedSub {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #533eae;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 37px;
    margin-bottom: 16px;
  }

  .userName {
    @include jpNotoSans3;
    margin-bottom: 32px;
    color: #888888;
    max-width: 270px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .userMenuItem {
    @include jpParagraph07;
    color: #ffffff;
    height: 40px;
    cursor: pointer;
  }
}
