@import "src/assets/styles/_variables.module.scss";

.backGroundShadow {
  background: #23253ee5;
  opacity: 0.9;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.detailCardContainer {
  width: 400px;
  height: 681px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  background: #533eaeb2;
  box-shadow: 8px 8px 6px 0px #0000004d;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .line {
    border: 0;
    border-top: 1px #ffffff66 solid;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }

  .header {
    @include jpNotoSans2;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 400px;
    max-height: 44px;
    min-height: 44px;
    padding: 10px 20px;
    background: #533eae;
    color: #ffffff;
    border-radius: 10px 10px 0px 0px;
  }

  .mapImage {
    width: 400px;
    height: 100px;
    border-radius: 0px 0px 10px 10px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px 0px 10px 10px;
  }

  .banner {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 400px;
    max-height: 537px;
    min-height: 537px;
    border-radius: 10px;
    padding: 16px 30px;
    backdrop-filter: blur(3px);
    display: flex;
    flex-direction: column;
    align-items: start;
    color: $cTxBody;

    .title {
      @include jpHeadline02;
      margin-bottom: 8px;
      overflow: hidden;
      overflow-wrap: break-word;
      max-height: 60px;
      min-height: 60px;
      max-width: 100%;
    }

    .ownerInfo {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: 100%;
      max-height: 24px;
      min-height: 24px;

      .ownerName {
        @include jpParagraph03;
      }

      .ownerIcon {
        margin-right: 10px;
        font-size: 24px;
      }
    }

    .eventDateInfo {
      display: flex;
      max-height: 24px;
      min-height: 24px;
      margin-bottom: 4px;
      color: $cTxBody;
      display: flex;
      align-items: center;

      .date {
        @include jpNotoSans2;
      }

      .calendarIcon {
        margin-right: 10px;
      }
    }

    .membersInfo {
      max-height: 24px;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: start;
      color: $cTxBody;

      .groups {
        font-size: 24px;
        margin-right: 10px;
      }

      .numbersInfo {
        @include jpNotoSans2;
      }
    }

    .description {
      @include hideScrollBar;
      @include jpParagraph06;
      overflow: scroll;
      overflow-wrap: break-word;
      max-width: 100%;
      max-height: 51px;
      min-height: 51px;
      margin-bottom: 16px;
    }

    .keywords {
      @include hideScrollBar;
      max-width: 100%;
      min-height: 23px;
      max-height: 23px;
      overflow: scroll;
    }

    .keyword {
      @include jpParagraph05;
      margin-right: 8px;
      padding: 4px 10px;
      background: #ffffff1a;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  .nameOutline {
    border-radius: 11px;
    border: 1px solid #fff;
    width: 72px;
    height: 21px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .eventDateInfo {
    height: 24px;
    margin-bottom: 4px;
    color: $cTxBody;
    display: flex;
    align-items: center;

    .date {
      @include jpNotoSans2;
    }

    .calendarIcon {
      margin-right: 10px;
    }
  }

  .mapInfo {
    height: 24px;
    color: $cTxBody;
    display: flex;
    align-items: center;

    .mapIcon {
      margin-right: 10px;
    }

    .mapName {
      @include jpNotoSans2;
    }
  }

  .membersInfo {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 4px;
    color: $cTxBody;

    .groupsIcon {
      font-size: 24px;
      margin-right: 10px;
    }

    .numbersInfo {
      @include jpNotoSans2;
    }
  }

  // .detailInfo {
  //   margin-top: 30px;
  //   align-items: center;
  //   z-index: 1;
  //   display: flex;
  //   align-items: center;
  //   flex-direction: column;
  //   width: 614px;
  //   @include jpParagraph02;
  //   color: $cTxBody;

  .settingsInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;

    .settingDetail {
      height: 30px;
      display: flex;
      align-items: center;
      margin-right: 50px;

      .settingName {
        margin-left: 2px;
        width: 150px;
        @include jpNotoSans;
      }

      .details {
        margin-left: 10px;
        @include jpParagraph06;
      }
    }

    .rightIcon {
      font-size: 20px;
      color: #ffffff;
      margin-left: 10px;
    }
  }
}

.joinButton {
  position: absolute;
  bottom: -30px;
  z-index: 1;
}

.filledStyle {
  font-size: 20px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

.editButtons {
  position: absolute;
  bottom: -30px;
  z-index: 1;
  gap: 10px;
  display: flex;
}

.byRoomPlayer {
  min-height: 532px;
}

.byRoomOwner {
  min-height: 592px;
}
