@import "src/assets/styles/_variables.module.scss";

.inputText {
  border: unset;
  box-sizing: border-box;
  width: 100%;
  max-width: 434px;
  min-width: 286px;
  height: 40px;
  padding: 10px 70px 10px 20px;
  background-color: $cBgSecondary;
  border-radius: 10px;
  color: #ffffff;
  outline: none;
  @include jpParagraph02;
}

.passwordInput {
  position: relative;
  min-width: 286px;
}

.maskButton {
  position: absolute;
  padding: 0;
  top: 50%;
  right: 40px;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  transform: translateY(-50%);

  .maskIcon {
    font-size: 20px;
  }
}

.errorMessage {
  margin-top: 8px;
  color: $cTxAlert;
  margin: none;
  @include jpParagraph05;
}

.copyButton {
  position: absolute;
  padding: 0;
  top: 50%;
  right: 10px;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  transform: translateY(-50%);

  .copyIcon {
    font-size: 20px;
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
  }
}

.tooltip {
  border-radius: 10px !important;
  border: 1px #ffffff solid !important;
  text-align: center;
  background-color: #533eaee5 !important;
  margin-top: 10px;
}

.openNewTagButton {
  position: absolute;
  padding: 0;
  top: 50%;
  right: 40px;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  transform: translateY(-50%);

  .openNewTagIcon {
    font-size: 20px;
  }
}
