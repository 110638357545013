@import "src/assets/styles/_variables.module.scss";

.modalWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.modalMask {
  position: fixed;
  inset: 0;
  background-color: #23253ee5;
  pointer-events: none;
  z-index: 2;
}

.modalContainer {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  z-index: 3;
}

.error {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .errorTitle {
    @include enHeadline01;
  }

  .errorMessage {
    @include jpHeadline02;
    margin-bottom: 20px;
  }
}
