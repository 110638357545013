@import "src/assets/styles/_variables.module.scss";

.title {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .main {
    margin-right: 40px;
    @include enHeadline01;
    color: $cTxBody;
  }

  .sub {
    @include jpHeadline01;
    color: $cTxBody;
  }
}
