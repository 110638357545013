@import "src/assets/styles/_variables.module.scss";

.tools {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
}

.newsDetailContentWrapper {
  @include hideScrollBar;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: scroll;

  @media (max-width: 1365px) {
    min-width: 640px;
  }

  .newsDetailContentContainer {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px 120px;
  }

  .newsDetailContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

    .newsDetail {
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 40px 48px 40px 48px;
      width: 700px;
      background: #191a2db2;
      border-radius: 10px;

      .articleWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 20px;
        margin-bottom: 20px;

        .typeIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 30px;
          background: #ffffff;
          border-radius: 10px;

          span {
            @include jpParagraph03;
            color: $cBgPrimary;
            line-height: 14px;
          }
        }

        .newsDate {
          @include fNotoSansJP18;
          color: #ffffff;
        }
      }

      .newsTitle {
        @include fBoldNotoSansJP;
        color: #ffffff;
        width: 100%;
        letter-spacing: 2.4px;
        margin-bottom: 40px;
        line-height: 27px;
        letter-spacing: 0.1em;
        text-align: left;
      }

      .imageWrapper {
        margin-bottom: 40px;
        width: 604px;

        .articleImage {
          width: 100%;
          height: auto;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .contentTitle1 {
        height: auto;
        margin-bottom: 20px;
        color: #fff;
        font-family: "Noto Sans JP";
        font-size: 20px;
        font-style: normal;
        font-weight: bold;
        line-height: 27px;
        letter-spacing: 1.6px;
      }

      .contentTitle2 {
        height: auto;
        margin-bottom: 20px;
        color: #fff;
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: bold;
        line-height: 27px;
        letter-spacing: 1.6px;
      }

      .contentDetail {
        height: auto;
        margin-bottom: 16px;
        color: #fff;
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 1.6px;
        white-space: pre-wrap;
      }

      .contentImageWrapper {
        margin-bottom: 40px;
        width: 604px;

        .contentImage {
          width: 604px;
          height: auto;
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }
  }
}
