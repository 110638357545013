@import "src/assets/styles/_variables.module.scss";

.navigation {
  width: 275px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  background: transparent;
  border: 0px;
  padding: 0;
  cursor: pointer;
  transition: background 0.3s ease;
  @media only screen and (max-width: 1349px) {
    width: 110px;
    height: 50px;
  }

  &:disabled {
    background: $cBgAccent;
    cursor: auto;
  }
}

.icon {
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 10px 0 40px;

  @media only screen and (max-width: 1349px) {
    margin: 0 auto;
  }
}

.text {
  @include jpParagraph03;
  color: $cTxBody;
  line-height: 1;
  @media only screen and (max-width: 1349px) {
    display: none;
  }
}
