@import "src/assets/styles/_variables.module.scss";

.roomHeaderContainer {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 20px;
  top: 0px;
  height: 80px;
  display: grid;
  width: 100%;
  background: #191a2dcc;
  position: absolute;
  z-index: 99;
  min-width: 960px;
  grid-template-columns: 90px 1fr 1fr;

  .roomLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 90px;
    height: 60px;
  }

  .roomLogo {
    width: 44px;
  }

  .roomInfo {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    color: #ffffff;
    justify-content: center;
  }

  .roomTitle {
    @include fRegularNotoSansJP400;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 474px;
    max-height: 21px;
    white-space: nowrap;
  }

  .roomDetail {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 27px;
    white-space: nowrap;
  }

  .eventDateTimeItem {
    @include jpHeadline03;
  }

  .eventDateTimeInfo {
    @include jpHeadline03;
  }

  .separator {
    @include jpParagraph03;
    margin-left: 10px;
    margin-right: 10px;
  }

  .ownerNameItem {
    @include jpParagraph03;
  }

  .ownerName {
    @include jpParagraph03;
    color: #533eae;
    text-decoration: underline;
  }

  .subMenu {
    display: flex;
    align-items: center;
    justify-self: end;
  }

  .clickSchedule {
    @include jpParagraph05;
    text-decoration: underline;
    color: #ff9b9b;
    cursor: pointer;
    margin-left: 10px;
  }

  .buttonBackGround {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 16px;
  }

  .calendarButton {
    font-size: 18px;
    color: #533eae;
    border: none;
  }

  .roomInfoButton {
    font-size: 18px;
    color: #533eae;
    border: none;
  }
}
