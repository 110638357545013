@import "src/assets/styles/_variables.module.scss";

.inputContainer {
  display: flex;
}

.inputText {
  border: unset;
  box-sizing: border-box;
  max-width: 138px;
  height: 40px;
  padding: 10px 30px 10px 30px;
  background-color: $cBgSecondary;
  border-radius: 10px;
  color: #ffffff;
  outline: none;
  @include jpParagraph02;

  &:focus {
    border: 1px solid $cBgWhite;
  }
  &:disabled {
    background: rgba($color: #ffffff, $alpha: 0.1);
    cursor: default;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}

.error {
  border: 1px solid $cTxAlert;

  &:focus {
    border-color: $cTxAlert;
  }
}

.errorMessage {
  margin-top: 8px;
  color: $cTxAlert;
  margin: none;
  @include jpParagraph05;
}

.description {
  position: relative;
  right: 30px;
  top: 8px;
  color: #ffffff;
  @include jpParagraph02;
}
