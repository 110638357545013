@import "src/assets/styles/_variables.module.scss";

.cardContainer {
  width: 400px;
  height: 352px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background: #191a2db2;
  box-shadow: 8px 8px 6px 0px #0000004d;
  cursor: pointer;

  &:hover {
    .header {
      background: #533eae;
    }

    background: #533eaeb2;
  }

  .line {
    border: 0;
    border-top: 1px #ffffff66 solid;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }

  .header {
    @include jpNotoSans2;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 400px;
    max-height: 44px;
    min-height: 44px;
    padding: 10px 20px;
    background: #23253e;
    color: #ffffff;
  }

  .mapImage {
    width: 400px;
    height: 100px;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 400px;
    max-height: 208px;
    min-height: 208px;
    border-radius: 10px;
    padding: 16px 30px;
    backdrop-filter: blur(3px);
    display: flex;
    flex-direction: column;
    align-items: start;
    color: $cTxBody;

    .title {
      @include jpHeadline02;
      margin-bottom: 8px;
      overflow: hidden;
      overflow-wrap: break-word;
      max-height: 60px;
      min-height: 60px;
      max-width: 100%;
    }

    .ownerInfo {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: 100%;
      max-height: 24px;
      min-height: 24px;

      .ownerName {
        @include jpParagraph03;
      }

      .ownerIcon {
        margin-right: 10px;
        font-size: 24px;
      }
    }

    .eventDateInfo {
      display: flex;
      max-height: 24px;
      min-height: 24px;
      margin-bottom: 4px;
      color: $cTxBody;
      display: flex;
      align-items: center;

      .date {
        @include jpNotoSans2;
      }

      .calendarIcon {
        margin-right: 10px;
      }
    }

    .membersInfo {
      max-height: 24px;
      min-height: 24px;
      display: flex;
      align-items: center;
      justify-content: start;
      color: $cTxBody;

      .groups {
        font-size: 24px;
        margin-right: 10px;
      }

      .numbersInfo {
        @include jpNotoSans2;
      }
    }
  }
}

.filledStyle {
  font-size: 20px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}
