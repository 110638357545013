@import "src/assets/styles/_variables.module.scss";

.inputText {
  border: unset;
  box-sizing: border-box;
  width: 100%;
  min-width: 260px;
  height: 40px;
  padding: 10px;
  background-color: $cBgSecondary;
  border-radius: 10px;
  color: #ffffff;
  outline: none;
  @include jpParagraph02;

  &:focus {
    border: 1px solid $cBgWhite;
  }
  &:disabled {
    background: rgba($color: #ffffff, $alpha: 0.1);
    cursor: default;
  }
}

.error {
  border: 1px solid $cTxAlert;

  &:focus {
    border-color: $cTxAlert;
  }
}

.passwordInput {
  position: relative;
  min-width: 260px;
}

.maskButton {
  position: absolute;
  padding: 0;
  top: 50%;
  right: 10px;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  transform: translateY(-50%);
  .maskIcon {
    font-size: 20px;
  }
}

.errorMessage {
  margin-top: 8px;
  color: $cTxAlert;
  margin: none;
  @include jpParagraph05;
}
