@import "src/assets/styles/_variables.module.scss";
.footer {
  width: 100%;
  height: auto;
  margin: auto 0 0;
  // background: $cBgPrimary;
  background: #383366;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 120.162px;
  height: 78px;
  margin-top: 65px;
  margin-bottom: 45px;
}

.linkWrapper {
  display: inline-flex;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 35px;
}

.link {
  color: #fff;
  font-family: "Noto Sans JP";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 138.462% */
  letter-spacing: 1.3px;

  a {
    color: #fff;
    text-decoration: none;
  }
}

.logoX {
  width: 20px;
  height: 20px;
}

.copyRight {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 750px;
  color: #ccc;
  font-family: "Noto Sans JP";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 180% */
  letter-spacing: 1px;
}
