@import "src/assets/styles/_variables.module.scss";

.voiceChat {
  height: 112px;
  width: 380px;
  background-color: #191a2dcc;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 0 20px;

  .mic {
    font-size: 30px;
  }

  .headphone {
    font-size: 30px;
  }
}

.voiceChatLeft {
  width: 250px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
}

.voiceChatRight {
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.settings {
  font-size: 20px;
  position: absolute;
  color: #ffffff;
  cursor: pointer;
  margin-right: -160px;
  margin-top: 50px;
}

.callEnd {
  color: #ff9b9b;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  color: #ffffff;
  background-color: #c34343;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.mic {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  color: #533eae;
  background-color: #ffffff;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.headphone {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  color: #533eae;
  background-color: #ffffff;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.disabled {
  color: #ffffff;
  background-color: #b8b8b8;
}

.voiceMenu {
  margin-left: 20px;
  width: 230px;
}

.voiceChatMembers {
  .voiceChatMemberInfo {
    margin-bottom: 7px;
    display: flex;
    align-items: center;

    .audio {
      display: none;
    }

    .chatting {
      font-size: 20px;
      color: #ffffff;
    }

    .speaking {
      color: #7ecc60;
    }

    .userName {
      font-family: Noto Sans JP;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.1em;
      text-align: left;
      color: #aeb1cc;
      margin-left: 10px;
    }
  }
}

.hide {
  display: none;
}
