@import "src/assets/styles/_variables.module.scss";

.inputTextArea {
  position: relative;

  .TextArea {
    border: unset;
    resize: none;
    display: inline-flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    word-wrap: break-word;
    word-break: break-all;
    gap: 3px;
    width: 434px;
    height: 76px;
    background-color: $cBgSecondary;
    border-radius: 10px;
    outline: none;
    color: white;
    @include jpParagraph02;
    box-sizing: border-box;
    overflow: hidden;

    &::placeholder {
      color: #ffffff4d;
    }

    &:focus {
      border: 1px solid $cBgWhite;
    }
  }

  .title {
    height: 88px;
  }

  .description {
    height: 116px;
  }

  .countBox {
    position: absolute;
    @include jpParagraph05;
    top: 70%;
    right: 5px;
    color: $cTxPlaceHolder;
    &.normal {
      color: $cTxBody;
    }
  }
  &.error {
    .TextArea {
      border: 1px solid $cTxAlert;
    }
  }
}

.errorInput {
  color: $cTxAlert;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  .errorMessage {
    margin: none;
    @include jpParagraph05;
  }
}
