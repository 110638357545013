@import "src/assets/styles/_variables.module.scss";

.infoContentContainer {
  padding-top: 80px;
  width: 100%;
  min-width: 1080px;
  background: #23253e;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: scroll;
}

.infoContent {
  max-width: 700px;
  width: 100%;
  height: 100%;
  margin-left: 15.625vw;
  margin-bottom: 30px;
}
