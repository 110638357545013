@import "src/assets/styles/_variables.module.scss";

.inputContainer {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 222px;
  height: 30px;
  background-color: #e9e9e9;
  border-radius: 20px;
  padding: 7px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputText {
  border: unset;
  box-sizing: border-box;
  width: 158px;
  height: 20px;
  color: #533eae;
  outline: none;
  @include jpParagraph03;

  &:focus {
    border: 1px solid #533eae;
  }
  &:disabled {
    background-color: #e9e9e9;
    cursor: pointer;
  }

  &::placeholder {
    color: #533eae;
  }
}

.error {
  border: 1px solid $cTxAlert;

  &:focus {
    border-color: $cTxAlert;
  }
}

.passwordInput {
  position: relative;
  min-width: 206px;
}

.errorMessage {
  margin-top: 8px;
  color: $cTxAlert;
  margin: none;
  @include jpParagraph05;
}

.customCodeButton {
  cursor: pointer;
  color: #533eae;
  font-size: 20px;
  margin-left: 16px;
  font-size: 20px;
  width: 20px;
  height: 20px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

.toolTipWrapper {
  background: transparent;
  width: 170px;
  height: 20px;
  margin-right: 40px;
  position: absolute;
  cursor: pointer;

  .customCodeCopyToolTip {
    border-radius: 10px !important;
    border: 1px #ffffff solid !important;
    text-align: center;
    background-color: #533eaee5 !important;
    left: 0 !important;
    top: 40px !important;
  }
}
