@import "src/assets/styles/_variables.module.scss";

.root {
  width: 100%;
  height: 100%;
  padding: 80px 0;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.table {
  max-width: 700px;
  width: 63%;
  height: auto;

  tr {
    width: 100%;
    height: auto;
  }

  th,
  td {
    padding: 0;
    text-align: left;
    color: $cTxBody;
    &:first-child::before {
      content: "";
      vertical-align: middle;
      display: inline-block;
      min-height: 40px;
    }
  }

  th {
    width: 24%;
    height: auto;

    @include jpParagraph03;
  }

  td {
    width: 76%;
    height: auto;
  }
}

.delateButton {
  padding: 0;
  border: none;
  background-color: transparent;
  color: $cTxBody;
  cursor: pointer;
  text-decoration: underline;
  line-height: 1;
  @include jpParagraph04;
}
