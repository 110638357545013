@import "src/assets/styles/_variables.module.scss";

.messageInfo {
  margin-bottom: 20px;

  .name {
    color: #ffffff80;
    font-family: "Noto Sans JP";
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-align: left;
  }

  .message {
    color: #ffffff;
    font-family: "Noto Sans JP";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1.6px;
  }

  .ownerMension {
    @extend .message;
    color: #7D5FFF;
  }

  .currentUserMension {
    @extend .message;
    color: $cTxAlert;
  }

  .otherUserMension {
    @extend .message;
    color: #6B70A5;
  }
}