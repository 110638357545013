@import "src/assets/styles/_variables.module.scss";

.tiny {
  border: none;
  width: 50px;
  height: 50px;
  color: $cBgWhite;
  background: #ffffff;
  border-radius: 25px;
  transition: 0.5s;
  cursor: pointer;

  .materialSymbolsRounded {
    font-family: "Material Symbols Rounded";
    font-size: 40px;
  }

  &:hover {
    background: #ffffff;
    opacity: 0.2;
  }
  &:active {
    background: $cBgSecondary;
    opacity: 1;
  }
  &:disabled {
    background: #b8b8b8;
    opacity: 1;
    cursor: default;
  }
}

.filterIcon {
  color: #533eae;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
