@import "src/assets/styles/_variables.module.scss";

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-width: 836px;
  min-height: 960px;
  height: 100vh;

  @media (max-width: 1365px) {
    width: 100vw;
    min-width: 960px;
    min-height: 960px;
  }

  .modalMask {
    inset: 0;
    width: 100vw;
    min-width: 836px;
    min-height: 960px;
    height: 100vh;
    background-color: #23253ee5;
    pointer-events: none;
    justify-self: flex-end;

    @media (max-width: 1365px) {
      width: 100vw;
      min-width: 960px;
      min-height: 960px;
    }
  }

  .modalContainer {
    @include hideScrollBar;
    width: 456px;
    min-height: 250px;
    z-index: 1;
    background-color: #45407c;
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 10px;
    align-items: center;
    padding: 40px 48px 40px 48px;
    overflow-x: hidden;
  }

  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ffffff;
    cursor: pointer;
    font-size: 40px;
  }

  .title {
    //styleName: 日本語見出し01;
    @include jpHeadline01;
    text-align: center;
    color: #ffffff;
  }

  .subTitle {
    @include jpParagraph05;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
    color: #ffffff;
  }

  .subText {
    text-decoration: underline;
    cursor: pointer;
  }

  .joinButton {
    margin-top: 20px;
  }
}

.contents {
  margin-top: 25px;
  min-width: 456px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  padding: 15px 0 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .inputName {
    display: flex;
    align-items: center;

    .required {
      border-radius: 5px;
      @include jpNotoSans;
      text-align: center;
      background-color: #c34343;
      color: #ffffff;
      width: 41px;
      height: 15px;
      margin-left: 10px;
    }

    .name {
      //styleName: 日本語本文03;
      @include jpParagraph03;
      text-align: left;
      color: #ffffff;

      .nameDescription {
        //styleName: 日本語本文05;
        @include jpParagraph05;
        text-align: left;
        color: #ffffff;
      }
    }
  }

  .inputStyle {
    position: absolute;
    right: 48px;
    width: 260px;

    .inputDescription {
      //styleName: 日本語本文05;
      @include jpParagraph05;
      text-align: left;
      color: #ffffff;
      margin-top: 8px;
    }

    .joinError {
      @include jpParagraph05;
      text-align: left;
      color: #ff9b9b;
      margin-top: 8px;
    }
  }
}

.accessByURL {
  width: 100vw !important;
}

.separation {
  border: 0;
  border-top: 1px solid #383366;
  width: 552px;
  margin: 15px 0 15px -48px;
}
