.mapCanvas {
  background: transparent;
  width: 1240px;
  height: 772px;
  object-fit: contain;

  @media (max-width: 1466px) {
    width: 740px;
    height: 740px;
  }
}
