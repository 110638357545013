@import "src/assets/styles/_variables.module.scss";

.modalWrapper {
  @include hideScrollBar;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .modalMask {
    position: fixed;
    inset: 0;
    height: 100%;
    background-color: #23253ee5;
    pointer-events: none;
    z-index: 3;
  }

  .warnModalContainer {
    width: 552px;
    border-radius: 10px;
    background-size: cover;
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    background-color: #45407c;
    padding: 40px 48px 40px 48px;
    z-index: 3;

    .header {
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .closeButton {
      cursor: pointer;
      color: #ffffff;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 40px;
    }

    .title {
      //styleName: 日本語見出し01;
      @include jpHeadline01;
    }

    .subTitle {
      @include jpParagraph03;
    }

    .resultSubTitle {
      @include jpParagraph05;
      display: flex;
      justify-content: center;
      margin-top: 30px;
      flex-direction: column;
      align-items: center;
    }

    .warnDescription {
      @include jpParagraph02;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 15px;
    }

    .bottom {
      margin-top: 25px;
      min-width: 620px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: row;
    }

    .backToResult {
      @include jpParagraph02;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
