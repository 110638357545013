@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500;700&display=swap");
@import "src/assets/styles/_variables.module.scss";

.primary {
  /* 日本語見出し02 */
  @include jpHeadline02;
  text-align: center;
  color: $cTxBody;

  border: none;
  padding: 0%;
  width: 300px;
  height: 50px;
  background: $cBgAccent;
  border-radius: 25px;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    opacity: 0.2;
    background: $cBgAccent;
  }
  &:active {
    opacity: 1;
    background: $cBgSecondary;
  }
  &:disabled {
    background: #b8b8b8;
    cursor: default;
    opacity: 1;
    color: rgba(255, 255, 255, 0.5);
  }
}

.secondary {
  /* 日本語見出し02 */
  @include jpHeadline02;
  color: $cTxAccent;
  text-align: center;

  border: none;
  width: 300px;
  height: 50px;
  background: $cBgWhite;
  border-radius: 25px;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    opacity: 0.2;
    background: $cTxBody;
  }
  &:active {
    opacity: 1;
    background: $cBgSecondary;
    color: $cTxBody;
  }
  &:disabled {
    background: #b8b8b8;
    opacity: 1;
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
  }
}

.third {
  /* 日本語見出し02 */
  @include jpHeadline02;
  color: $cTxAccent;
  text-align: center;

  border: none;
  width: 200px;
  height: 50px;
  background: $cBgWhite;
  border-radius: 25px;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    opacity: 0.2;
    background: $cTxBody;
  }
  &:active {
    opacity: 1;
    background: $cBgSecondary;
    color: $cTxBody;
  }
  &:disabled {
    background: #b8b8b8;
    opacity: 1;
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
  }
}

.fourth {
  @include jpNotoSans;
  color: $cTxAccent;
  text-align: center;

  border: none;
  width: 138px;
  height: 30px;
  background: $cBgWhite;
  border-radius: 25px;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    opacity: 0.2;
    background: $cTxBody;
  }
  &:active {
    opacity: 1;
    background: $cBgSecondary;
    color: $cTxBody;
  }
  &:disabled {
    background: #b8b8b8;
    opacity: 1;
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
  }
}

.fifth {
  /* 日本語見出し02 */
  @include fNotoSansJP24;
  color: #ffffff;
  text-align: center;
  border: 2px solid #23253e;
  width: 170px;
  height: 40px;
  background: #533eae;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    opacity: 0.2;
    background: $cTxBody;
  }
  &:active {
    opacity: 1;
    background: $cBgSecondary;
    color: $cTxBody;
  }
  &:disabled {
    background: #b8b8b8;
    opacity: 1;
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
  }
}

.sixth {
  /* 日本語見出し02 */
  @include fNotoSansJP24;
  color: #533eae;
  text-align: center;
  border: 2px solid #23253e;
  width: 170px;
  height: 40px;
  background: #ffffff;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    opacity: 0.2;
    background: $cTxBody;
  }
  &:active {
    opacity: 1;
    background: $cBgSecondary;
    color: $cTxBody;
  }
  &:disabled {
    background: #b8b8b8;
    opacity: 1;
    color: rgba(255, 255, 255, 0.5);
    cursor: default;
  }
}
