@import "src/assets/styles/_variables.module.scss";

.roomHeaderContainer {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 20px;
  top: 0px;
  height: 80px;
  display: flex;
  width: 100%;
  background: #191a2dcc;
  position: absolute;
  z-index: 99;
  min-width: 640px;

  .roomLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 90px;
    height: 60px;
  }

  .roomLogo {
    width: 44px;
  }

  .roomInfo {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    color: #ffffff;
    justify-content: center;
  }

  .roomTitle {
    @include fRegularNotoSansJP400;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 474px;
    max-height: 21px;
    white-space: nowrap;
  }

  .roomDetail {
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 27px;
    white-space: nowrap;
  }

  .eventDateTimeItem {
    @include jpHeadline03;
  }

  .eventDateTimeInfo {
    @include jpHeadline03;
  }

  .separator {
    @include jpParagraph03;
    margin-left: 10px;
    margin-right: 10px;
  }

  .ownerNameItem {
    @include jpParagraph03;
  }

  .ownerName {
    @include jpParagraph03;
    color: #533eae;
    text-decoration: underline;
  }

  .clickSchedule {
    @include jpParagraph05;
    text-decoration: underline;
    color: #ff9b9b;
    cursor: pointer;
    margin-left: 10px;
  }
}

.headerContainer {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 20px;
  top: 0px;
  height: 80px;
  display: flex;
  width: 100%;
  background: #383366e5;
  position: fixed;
  z-index: 99;
  min-width: 640px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 206px;
}

.menuContainer {
  margin-left: 40px;
  display: flex;
  align-items: center;

  .headerItem {
    @include enMenu01;
    margin-right: 10px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px 24px;
    height: 50px;
    display: flex;
    align-items: center;
    color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    justify-content: center;
  }

  .roomItem {
    width: 158px;
  }

  .friendsItem {
    width: 212px;
  }

  .informationItem {
    width: 220px;
  }

  .selected {
    background: #533eae;
  }

  .headerHide {
    color: #ffffff33;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .itemHide {
    color: #ffffff33;
  }

  .comingSoon {
    @include enMenu02;
    color: #ffffff;
    transform: rotate(-10deg);
    position: absolute;
  }

  .dropDownIcon {
    margin-left: 10px;
  }

  .dropDown {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    top: 90px;
    position: absolute;
    justify-content: center;
    display: flex;
    background-color: #383366;
    border-radius: 10px;
    padding: 10px 0px;
    flex-direction: column;
  }

  .informationDropDown {
    width: 220px;
    height: 80px;
  }

  .dropDownItem {
    @include jpNotoSans;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    height: 30px;
  }
}

.menuButton {
  position: absolute;
  color: #ffffff;
  font-size: 50px;
  right: 20px;
  cursor: pointer;
  align-self: center;
}

.headerDetails {
  position: fixed;
  width: 390px;
  height: 100%;
  z-index: 2;
  right: 0;
  top: 0;
  background: #23253e;
  box-shadow: -6px 0px 10px 0px #00000033;
  padding: 10px 20px 0 20px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .detailLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 90px;
    height: 60px;
  }

  .detailLogo {
    width: 44px;
  }

  .closeButton {
    cursor: pointer;
    color: #ffffff;
    font-size: 32px;
    height: 32px;
    width: 32px;
  }

  .bottomContent {
    position: fixed;
    bottom: 40px;
  }

  .content {
    margin-top: 40px;

    .detailItem {
      @include fSairaJP20;
      padding: 15px 40px;
      margin-bottom: 16px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    .subDetailItem {
      @include fNotoSansJP15;
      padding: 0px 40px;
      margin-bottom: 20px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    .selected {
      background: #533eae;
      border-radius: 10px;
    }

    .detailItemHide {
      opacity: 0.4;
      cursor: initial;
    }
  }
}

.backGroundShadow {
  background: #23253ee5;
  opacity: 0.9;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.slidein {
  animation: slideinLeft 0.8s;

  @keyframes slideinLeft {
    0% {
      transform: translateX(100vw);
    }
    100% {
      transform: translateX(0);
    }
  }
}

.slideout {
  animation: slideoutLeft 0.8s;

  @keyframes slideoutLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100vw);
    }
  }
}
