@import "src/assets/styles/_variables.module.scss";

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-width: 836px;
  min-height: 960px;
  height: 100vh;
}

.modalMask {
  inset: 0;
  width: 100vw;
  min-width: 836px;
  min-height: 960px;
  height: 100vh;
  background-color: #23253ee5;
  pointer-events: none;
  justify-self: flex-end;

  @media (max-width: 1365px) {
    width: 100vw;
    min-width: 960px;
    min-height: 960px;
  }
}

.searchContainer {
  display: flex;
  justify-self: center;
  top: 30px;
  min-width: 750px;
  max-width: 830px;
  gap: 0px;
  padding: 25px 40px 25px 40px;
  border-radius: 25px;
  background-color: #45407c;
  z-index: 2;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: initial;
  height: initial;
  top: 100px;
}

.searchArea {
  width: 100%;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  font-size: 40px;
}

.contents {
  margin-top: 25px;
  min-width: 456px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .content {
    padding: 15px 0 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .insideContent {
    padding: 8px 0 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .separation {
    border: 0;
    border-top: 1px solid #383366;
    width: 680px;
    margin: initial;
    margin: 15px 0 15px -48px;
  }

  .inputName {
    display: flex;
    align-items: center;
    min-width: 106px;
    margin-right: 32px;

    .required {
      border-radius: 5px;
      @include jpNotoSans;
      text-align: center;
      background-color: #c34343;
      color: #ffffff;
      width: 41px;
      height: 15px;
      margin-left: 10px;
    }

    .name {
      //styleName: 日本語本文03;
      @include jpParagraph03;
      text-align: left;
      color: #ffffff;
    }

    .mapInputName {
      //styleName: 日本語本文03;
      @include jpParagraph03;
      text-align: left;
      color: #ffffff;
      margin-top: -138px;
    }
  }

  .inputText {
    border: unset;
    box-sizing: border-box;
    width: 206px;
    height: 26px;
    padding: 10px;
    background-color: #383366;
    border-radius: 5px;
    color: #ffffff;
    outline: none;
    @include jpParagraph03;
  }

  .urlCopyBox {
    width: 434px;
  }

  .fromTo {
    align-self: center;
    color: #ffffff;
    @include jpNotoSans;
  }

  .inputStyle {
    min-width: 410px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px; /* 行と行の間(縦方向)の余白 */
    column-gap: 10px;

    > input {
      width: 286px;
      height: 40px;
      border-radius: 10px;
      background-color: #383366;
    }

    .inputDescription {
      //styleName: 日本語本文05;
      @include jpParagraph05;
      text-align: left;
    }
  }

  .unSelectInputStyle {
    margin-top: -15px;

    .inputUnderDescription {
      @include jpParagraph05;
      color: #ffffff;
      width: 434px;
      margin-top: 20px;
    }
  }

  .itemBlock {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    select {
      appearance: none;
      /*  safari  */
      -webkit-appearance: none;
      /*  other styles for aesthetics */
      background-color: #383366;
      width: 286px;
      height: 40px;
      border-radius: 10px;
      @include jpParagraph03;
      text-align: left;
      color: #ffffff;
      padding-left: 20px;
      padding-right: 40px;
      cursor: pointer;
      text-overflow: ellipsis;
    }

    &::after {
      --size: 5px;
      content: "";
      position: absolute;
      right: 25px;
      pointer-events: none;
      margin-right: 10px;
      border-left: var(--size) solid transparent;
      border-right: var(--size) solid transparent;
      border-top: var(--size) solid white;
    }
  }

  .mapName {
    @include enParagraph03;
    color: #ffffff;
  }

  .image {
    box-sizing: border-box;
    width: 136px;
    height: 100px;
    border-radius: 10px;
    opacity: 0.5;
    object-fit: cover;
    cursor: pointer;
  }

  .selectedImage {
    border: 2px solid #ffffff;
    opacity: 1;
  }

  .resetSettings {
    @include jpNotoSans;
    color: #ffffff;
    margin-top: 22px;
    margin-bottom: 10px;
    text-decoration: underline;
    cursor: pointer;
    align-self: center;
  }
}

.bottomDescription {
  @include jpParagraph05;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 15px;
}

.bottom {
  margin-top: 25px;
  min-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  align-self: center;
}

.clearBotton {
  @include jpParagraph02;
  color: #ffffff;
  cursor: pointer;
}
