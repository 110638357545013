@import "src/assets/styles/_variables.module.scss";

.content {
  position: relative;
  padding: 10px 20px 10px 70px;
  background-color: $cBgSecondary;
  color: #ffffff;
  @include jpParagraph03;
  border-radius: 10px;
  width: 196px;
  min-width: 156px;
  height: 20px;
  align-items: center;
  display: flex;

  .calendarIcon {
    position: absolute;
    font-size: 30px;
    color: #ffffff;
    border: none;
    top: 5px;
    left: 20px;
    cursor: pointer;
  }

  .calendar {
    border: none;
    background-color: $cBgSecondary;
    color: #ffffff;
    width: 204px;
    @include jpParagraph03;

    > div {
      border: none;
    }
  }

  .clearButton {
    padding: 7px;
    display: flex;
    color: #ffffff;
    cursor: pointer;
    background: none;
    border: none;
    .icon {
      font-size: 16px;
    }
  }
}

.showCalendar {
  position: absolute;
}

.hide {
  display: none !important;
}
