.ImgBackGroundContainer {
  width: 100vw;
  height: 100vh;
  min-width: 640px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.backGroundShadow {
  background-color: #38336680;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  min-height: 960px;
  min-width: 640px;
  position: absolute;
}

.backGroundImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 960px;
  min-width: 640px;
  position: absolute;
  z-index: -1;
}
