@import "src/assets/styles/_variables.module.scss";

.signOutModal {
  width: 552px;
}

.signOutModalContent {
  padding: 40px 0;
  text-align: center;
}

.signOutModalTitle {
  margin-bottom: 40px;
  @include jpHeadline01;
  color: $cTxBody;
}

.signOutModalText {
  @include jpHeadline02;
  color: $cTxBody;
}

.signOutModalButton {
  width: 100%;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-top: 1px solid $cBgSecondary;
}
