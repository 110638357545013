@import "src/assets/styles/_variables.module.scss";

.noRoomsContainer {
  align-self: center;
  top: calc(50vh - 280px);
  position: relative;
  color: white;
  display: grid;

  @media (max-height: 960px) {
    top: 280px;
  }
}

.noRoomsIcon {
  justify-self: center;
  margin-bottom: 40px;
  font-size: 120px;
  width: 120px;
  height: 120px;
}

.noRoomsDescription {
  @include fBoldNotoSansJP2;
  justify-self: center;
  white-space: nowrap;
}
