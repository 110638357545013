@import "src/assets/styles/_variables.module.scss";

.root {
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: end;
  flex-direction: column;
}
.apexContentContainer {
  position: relative;
  @include hideScrollBar;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 80px;
  height: 100%;
  display: grid;
  width: 100%;
  padding-bottom: 220px;

  @media (max-width: 1365px) {
    min-width: 640px;
  }
}

.noRoomsContainer {
  justify-self: center;
  align-self: center;
  position: absolute;
  color: white;
  display: grid;
}

.noRoomsIcon {
  justify-self: center;
  margin-bottom: 40px;
  width: 120px;
  height: 115px;
}

.noRoomsDescription {
  @include fBoldNotoSansJP2;
  justify-self: center;
  white-space: nowrap;
}

.contentContainer {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 120px;
}

.roomHeader {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  padding-top: 50px;
  padding-bottom: 20px;
  color: white;
  align-items: center;
  height: 121px;

  .title {
    @include enHeadline01;
  }

  .subTitle {
    @include fBoldNotoSansJP;
    margin-left: 40px;

    @media screen and (max-width: 1099px) {
      display: none;
    }
  }
}

.cardsContainer {
  display: grid;
  gap: 60px;
  margin-top: 20px;
  justify-content: flex-start;
  grid-template-columns: repeat(auto-fill, 400px);
}

.tools {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
}

.addRoomButton {
  margin-bottom: 20px;
}

.filterRoomButton {
  margin-bottom: 40px;
}

.moreButton {
  position: absolute;
  left: 50%;
  bottom: 60px;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent;
  transform: translateX(-50%);
  cursor: pointer;
}

.moreButtonImage {
  display: block;
  width: 80px;
  height: 80px;
}

.moreButtonText {
  @include jpParagraph03;
  color: $cTxBody;
}

.rotate {
  animation: rotation 0.6s linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
