@import "src/assets/styles/_variables.module.scss";

p {
  margin: 0;
}

.articleTitle,
.articleSubTitle {
  width: 100%;
  text-align: left;
}

.articleTitle {
  @include jpHeadline01;
}

.articleLink {
  @include jpParagraph02;
  color: $cTxBody;
}

.termsWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.contentWrapper {
  @include jpParagraph02;
  color: $cTxBody;
  text-align: left;

  .articleWrapper {
    width: 660px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}

.listType1,
.listType2 {
  padding-inline-start: 30px;
  margin: 0;
}

.listType2 {
  list-style-type: none;
}

.listType3 {
  list-style-type: lower-roman;
}

.subList {
  padding: 0 30px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline-start: 0;
}

.dateContent {
  width: 100%;
  text-align: right;
}
