@import "src/assets/styles/_variables.module.scss";

.modalWrapper {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .modalMask {
    position: fixed;
    inset: 0;
    height: 100%;
    background-color: #23253ee5;
    pointer-events: none;
  }

  .scheduleContainer {
    width: 700px;
    height: 560px;
    border-radius: 10px;
    background-size: cover;
    z-index: 1;
    background-color: #423d77;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    transition: height 0.3s linear;
  }

  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
    cursor: pointer;
    font-size: 40px;
  }

  .header {
    margin-top: 40px;
    //styleName: 日本語見出し01;
    font-family: Noto Sans JP;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #ffffff;
  }

  .subHeader {
    width: 418px;
    margin-top: 30px;
    //styleName: 日本語本文05;
    font-family: Noto Sans JP;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #ffffff;
  }

  .contents {
    @include hideScrollBar;
    max-height: 400px;
    margin-top: 40px;
    margin-bottom: 40px;
    overflow-y: scroll;
  }

  .publicSchedule {
    display: flex;
    width: 600px;
    height: 114px;
    padding: 20px 0 20px 0;
    justify-content: space-evenly;
    align-items: center;
    background-color: #383366;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .schedule {
    display: flex;
    width: 600px;
    max-height: 114px;
    padding: 20px 0 20px 0;
    justify-content: space-evenly;
    align-items: center;
    background-color: #383366;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .confirmedSchedule {
    background-color: #ffffff;
  }

  .notConfirmedSchedule {
    opacity: 0.3;
  }

  .date {
    font-family: Saira;
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-align: left;
    color: #ffffff;
  }

  .confirmedDate {
    color: #533eae;
  }

  .block {
    position: relative;
    cursor: pointer;
    margin-bottom: 8px;
    display: flex;
  }

  .confirmedBlock {
    width: 50px;
    height: 50px;
  }

  .blockBackGround {
    color: #ffffff;
    width: 50px;
    height: 50px;
    top: 170px;
    left: 392px;
    gap: 0px;
  }

  .blockIcon {
    font-family: Noto Sans JP;
    font-size: 24px;
    font-weight: 700;
    line-height: 34.75px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #533eae;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 36px;
  }

  .selected {
    color: #ffffff;
  }

  .notConfirmed {
    color: #ffffff;
  }

  .number {
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #ffffff;
  }

  .confirmedNumber {
    color: #533eae;
  }

  .scheduleConfirmed{
    opacity: 0px;
  }
}
