@import "src/assets/styles/_variables.module.scss";

.button {
  width: 200px;
  height: 40px;
  border-radius: 25px;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  @include fNotoSansJP18;
}

.transparent {
  background-color: transparent;
  color: $cTxBody;
  border: 1px solid $cTxBody;
}

.white {
  background-color: $cBgWhite;
  color: $cTxAccent;
}

.red {
  background-color: #ec5e3f;
  color: $cTxBody;
}
