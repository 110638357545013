@import "src/assets/styles/_variables.module.scss";

p,
h2 {
  margin: 0;
}

.privacyPolicyWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.contentWrapper {
  @include jpParagraph02;
  color: $cTxBody;
  text-align: left;

  .content,
  .articleWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.articleSubWrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.articleLink {
  @include jpParagraph02;
  color: $cTxBody;
}

.listContentWrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;

  .listWrapper {
    .listTitle {
      @include jpHeadline02;
      margin-bottom: 10px;
      list-style-type: decimal;
    }

    .smallDescription {
      @include enParagraph03;
    }

    .defaultDescription {
      @include jpParagraph01;
      max-width: 600px;
      width: auto;
      height: auto;
      margin-top: 31px;
      margin-bottom: 12px;
    }

    .listItems {
      list-style: disc;
    }

    .listItemsWrapper {
      margin-top: 40px;

      .underLine {
        color: #ffffff;
        text-decoration-line: underline;
      }
    }
  }

  .dateContent {
    text-align: right;
  }
}
