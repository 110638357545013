@import "src/assets/styles/_variables.module.scss";

.content {
  position: relative;
  width: 134px;
  height: 30px;
  box-sizing: border-box;

  .keywordTextBox {
    padding: 10px 20px 10px 20px;
    width: 94px;
    height: 10px;
    border-radius: 10px;
    border: none;
    background-color: $cBgSecondary;
    color: #ffffff;
    @include jpParagraph03;

    &::placeholder {
      color: #ffffff4d;
    }

    &:focus {
      border: 1px solid #fff;
      border-radius: 10px;
      outline: none;
    }
  }

  .keywordButton {
    position: absolute;
    padding: 7px;
    top: 50%;
    right: -4px;
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;
    background: none;
    border: none;
    transform: translateY(-50%);
    .icon {
      font-size: 16px;
    }
  }
}
