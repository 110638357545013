@import "src/assets/styles/_variables.module.scss";

.body {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #423d77;
  border-radius: 10px;
  z-index: 3;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 18px;
    height: 2px;
    border-radius: 2px;
    transform-origin: center center;
    background-color: #fff;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(35, 37, 62, 0.9);
  z-index: 2;
}
