@import "src/assets/styles/_variables.module.scss";

.modalWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.modalMask {
  position: fixed;
  inset: 0;
  background-color: #23253ee5;
  pointer-events: none;
  z-index: 2;
}

.modalContainer {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  z-index: 3;
}

.loading {
  width: 100px;
  height: 100px;
  color: #ffffff;
  font-size: 100px;
  animation: rotate-anime 1s linear infinite;
  transform-origin: center;

  @keyframes rotate-anime {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.description {
  @include jpHeadline01;
  color: #ffffff;
}
