@import "src/assets/styles/_variables.module.scss";
@import "src/assets/styles/_responsive.module.scss";

.lpContainer {
  display: flex;
  flex-direction: column;
  background: #d7d8e2;
}

//タイトルイメージ
.headerContainer {
  width: 100%;
  max-height: 700px;
  overflow: hidden;

  .headerContent {
    display: flex;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1400/700;
    background: #5847bd;

    .bgImgContent {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      min-width: 1080px;
      aspect-ratio: 1400/700;

      max-height: 700px;

      .headerBackGroundImg {
        position: absolute;
        max-height: 700px;
        height: auto;
      }

      .gradiant {
        position: absolute;
        width: 100%;
        background-image: radial-gradient(
          circle,
          transparent 0%,
          transparent 40%,
          #5847bd 60%
        );
      }

      .overlayImg {
        position: absolute;
        background-image: url("../../assets/images/2x/dot.png");
        width: 100%;
        height: 700px;
      }

      .pcImg {
        position: absolute;
        height: 100%;
      }

      .titleWrapper {
        position: absolute;
        display: flex;
        justify-content: center;
        max-width: 100%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);

        .pcTitle {
          max-width: 816px;
          max-height: 334px;
          width: 120%;
          height: auto;
          flex-shrink: 0;
        }
      }

      .pcLogo {
        position: absolute;
        left: 0;
        width: 160px;
        height: 175px;
        flex-shrink: 0;
      }

      .startButtonWrapper {
        position: absolute;
        top: 73%;
        border-radius: 10px;
        background-color: #fff;

        .startButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 330px;
          height: 50px;
          flex-shrink: 0;
          border-radius: 5px;
          background: #ec5e3f;
          color: var(--TxBody, #fff);
          text-align: center;

          font-family: "Noto Sans JP";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          /* 187.5% */
          letter-spacing: 1.6px;
        }

        .startButton:hover {
          border-radius: 5px;
          background: rgba(236, 94, 63, 0.8);
          cursor: pointer;
        }
      }
    }
  }
}

.scrollDown {
  display: flex;
  flex-direction: column;
  width: 105px;
  height: 57px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0px;
  flex-shrink: 0;

  .rectangle {
    position: absolute;
    bottom: 0;
    width: 1px;
    height: 28px;
    left: 50%;
    background: var(--BgWhite, #fff);
  }

  .scrollText {
    position: absolute;
    bottom: 70px;
    width: 120px;
    height: 30px;
    color: var(--BgWhite, #fff);
    margin-bottom: 10px;
    font-family: Saira;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 111px;
    /* 853.846% */
    letter-spacing: 1.3px;
    text-transform: uppercase;
  }
}

//about
.aboutContainer {
  position: relative;
  width: 100%;
  height: 1071px;
  flex-shrink: 0;
  overflow: hidden;

  .aboutBg {
    position: relative;
    width: 100%;
    max-height: 1071px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;

    .aboutBgColor {
      position: absolute;
      width: 100%;
      height: 1071px;
      flex-shrink: 0;
      aspect-ratio: 1709/1071;
      background-color: #23253e;
    }

    .aboutBgImg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      object-fit: cover;
    }
  }

  .aboutContentWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    text-align: center;

    .aboutHeaderWrapper {
      margin-top: 95px;
      gap: 9px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .headerTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        width: 75px;
        height: 30px;
        color: var(--BgWhite, #fff);
        font-family: Saira;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        /* 150% */
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      .headerSubTitle {
        display: flex;
        height: 75px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;

        margin: 0;
        color: var(--TxBody, #fff);
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 75px;
        /* 150% */
        letter-spacing: 2.5px;
      }
    }
  }

  .image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: auto;

    .aboutImgWrapper {
      position: relative;
      width: 960px;
      height: 414px;
      flex-shrink: 0;

      .aboutScreenMask {
        position: absolute;
        width: 900px;
        height: 393px;
        flex-shrink: 0;
        top: 0;
        left: 0;
      }

      .aboutScreenImg {
        position: absolute;
        width: 960px;
        height: 414px;
        flex-shrink: 0;
        top: 0;
        left: 0;
      }
    }
  }

  .textContainer {
    margin-top: 57px;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: center;

    .aboutContentTitle {
      margin: 0;
      width: 771px;
      height: auto;
      color: var(--BgWhite, #fff);

      font-family: "Noto Sans JP";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
      /* 169.231% */
      letter-spacing: 2.6px;
    }

    .aboutContentDescriptionWrapper {
      margin-top: 28px;
      width: 771px;
      height: auto;
      display: flex;
      justify-content: left;

      .aboutContentDescription {
        margin: 0;
        width: 750px;
        color: var(--BgWhite, #fff);
        font-family: "Noto Sans JP";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 200% */
        letter-spacing: 1.6px;
      }
    }
  }
}

//FEATURES
.featuresContainer {
  width: 100%;
  // height: 4964px;

  .featuresContentWrapper {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    text-align: center;

    .featuresHeaderWrapper {
      margin-top: 95px;
      width: 100%;

      .featuresHeaderTitle {
        color: var(--BgPrimary, #23253e);
        font-family: Saira;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      .featuresHeaderSubTitle {
        margin: 0;
        margin-top: 10px;
        color: $cTxAccent;
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        /* 88% */
        letter-spacing: 2.5px;
      }
    }

    .featuresContent {
      margin-top: 86px;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 90px;

      .featuresWrapper1 {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 1004px;
        height: 298.507px;

        .contentWrapperType1 {
          display: inline-flex;
          align-items: center;
          gap: 64px;
          width: 1004px;
          height: 299px;

          .featuresImg_01 {
            width: 50%;
          }

          .featuresTextWrapper1 {
            width: 440px;
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 21.12px;

            .featuresTextTitle1 {
              margin: 0;
              width: 436px;
              height: 110px;
              color: $cTxAccent;

              font-family: "Noto Sans JP";
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              line-height: 55px;
              /* 152.778% */
              letter-spacing: 3.6px;
            }

            .featuresTextDescription1 {
              width: 440px;
              color: $cBgPrimary;

              font-family: "Noto Sans JP";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 27px;
              /* 192.857% */
              letter-spacing: 1.4px;
            }
          }
        }
      }

      .featuresWrapper2 {
        width: 1001.616px;
        height: 501px;

        .contentWrapperType2 {
          display: inline-flex;
          align-items: center;
          gap: 60px;
          width: 1002px;
          height: 501px;

          .featuresTextWrapper2 {
            width: 440px;
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 28px;

            .featuresTextTitle2 {
              margin: 0;
              width: 434px;
              color: $cTxAccent;

              font-family: "Noto Sans JP";
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              line-height: 55px;
              /* 152.778% */
              letter-spacing: 3.6px;
            }

            .featuresTextDescription2 {
              width: 440px;
              color: $cBgPrimary;

              font-family: "Noto Sans JP";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 27px;
              /* 192.857% */
              letter-spacing: 1.4px;
            }

            .featuresTextDescription5 {
              font-family: "Noto Sans JP";
              font-size: 10px;
              font-weight: 400;
              line-height: 27px;
              letter-spacing: 0.1em;
              text-align: left;
              color: #FF0000;
            }
          }

          .features2ImgWrapper {
            width: 501.616px;
            height: 501px;
            position: relative;

            .featuresImg_02 {
              width: 501.616px;
              height: 501px;
            }
          }
        }
      }

      .featuresWrapper3 {
        width: 1001.616px;
        height: 300px;

        .contentWrapperType1 {
          width: 1004px;
          height: 300px;
          display: inline-flex;
          align-items: center;
          gap: 64px;

          .featuresTextWrapper3 {
            width: 440px;
            height: auto;
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 28px;

            .featuresTextTitle3 {
              margin: 0;
              width: 434px;
              color: $cTxAccent;

              font-family: "Noto Sans JP";
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              line-height: 55px;
              /* 152.778% */
              letter-spacing: 3.4px;
            }

            .featuresTextDescription3 {
              width: 440px;
              color: $cBgPrimary;

              font-family: "Noto Sans JP";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 27px;
              /* 192.857% */
              letter-spacing: 1.4px;
            }
          }

          .features3ImgWrapper {
            width: 500px;
            height: 298.507px;

            .featuresImg_03 {
              width: 500px;
              height: 300px;
              flex-shrink: 0;
            }
          }
        }
      }

      .featuresWrapper4 {
        width: 1000px;
        height: 300px;

        .featuresWrapper4_content {
          display: inline-flex;
          align-items: center;
          gap: 60px;

          .featuresTextWrapper4 {
            width: 440px;
            height: auto;
            text-align: left;
            display: flex;
            flex-direction: column;
            gap: 28px;

            .featuresTextTitle4 {
              margin: 0;
              width: 440px;
              color: $cTxAccent;

              font-family: "Noto Sans JP";
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              line-height: 55px;
              /* 152.778% */
              letter-spacing: 3.6px;
            }

            .featuresTextDescription4 {
              width: 440px;
              color: $cBgPrimary;

              font-family: "Noto Sans JP";
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 27px;
              /* 192.857% */
              letter-spacing: 1.4px;
            }
          }

          .features4ImgWrapper {
            width: 500px;
            height: 300px;

            .featuresImg_04 {
              width: 500px;
              height: 300px;
              flex-shrink: 0;
            }
          }
        }
      }
    }
  }
}

.startContainer {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  margin-top: 150px;

  .startBgWrapper {
    width: 100%;
    height: 200px;
    position: relative;

    .startBg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
      background-color: $cBgTertiary;
    }

    .startImg {
      min-width: 1690px;
      width: 100%;
      height: 200px;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  .bottomStartButtonWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    background-color: #fff;

    .bottomStartButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 500px;
      height: 80px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #ec5e3f;
      color: var(--TxBody, #fff);
      text-align: center;

      font-family: "Noto Sans JP";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      /* 187.5% */
      letter-spacing: 1.6px;
    }

    .bottomStartButton:hover {
      border-radius: 10px;
      background: rgba(236, 94, 63, 0.8);
      cursor: pointer;
    }
  }
}

.shareWrapper {
  width: 100%;
  height: 104px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .shareContentWrapper {
    display: flex;

    width: 189px;
    height: 44px;
    gap: 11px;

    .shareText {
      color: $cBgPrimary;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 48px;
      /* 300% */
      letter-spacing: 1.6px;
      margin-right: 11px;
    }

    .shareIcon {
      width: 44px;
      height: 44px;
    }
  }
}

.footerWrapper {
  width: 100%;
  height: auto;
  background: $cBgPrimary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .footerLogo {
    width: 120.162px;
    height: 78px;
    margin-top: 65px;
    margin-bottom: 45px;
  }

  .footerLinkWrapper {
    display: inline-flex;
    align-items: flex-start;
    gap: 25px;
    margin-bottom: 35px;

    .footerLink {
      color: #fff;
      font-family: "Noto Sans JP";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      /* 138.462% */
      letter-spacing: 1.3px;

      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .logo_x {
    width: 20px;
    height: 20px;
  }

  .copyRightInfo {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 750px;
    color: #ccc;
    font-family: "Noto Sans JP";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 180% */
    letter-spacing: 1px;
  }
}

.newsContainer {
  background: #d7d8e2;
  width: 100%;
  display: flex;
  justify-content: center;

  .newsContent {
    width: 1000px;
    height: auto;
    margin-top: 75px;
    margin-bottom: 100px;

    @include sp {
      width: 100%;
      margin: 100px 40px;
    }

    .head {
      height: 48px;
      width: 100%;
      float: left;
      display: inline;

      @include sp {
        display: flex;
        flex-flow: column;
        height: auto;
      }

      border-bottom: 1px solid rgba($color: $cBgPrimary, $alpha: 0.3);

      .logo {
        color: $cBgAccent;
        font-family: $fSaira;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 2.6px;
        margin-right: 18px;
        margin-bottom: 11px;
      }

      .info {
        color: $cBgPrimary;
        font-family: $fNotoSansJP;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 1.3px;

        @include sp {
          margin-bottom: 25px;
        }
      }

      span {
        vertical-align: middle;
      }
    }

    .newsPosts {
      .news {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid rgba($color: $cBgPrimary, $alpha: 0.3);

        @include sp {
          flex-wrap: wrap;
        }

        .type {
          width: 85px;
          height: 26px;
          border-radius: 5px;
          background: $cBgPrimary;
          display: flex;
          align-items: center;

          img {
            margin-left: 9px;
            width: 8px;
            height: 8px;

            @include sp {
              width: 8px;
              height: 8px;
            }
          }

          span {
            margin-left: 8px;
            color: $cTxBody;
            font-family: $fSaira;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 18px;
            /* 150% */
            letter-spacing: 1.2px;
          }
        }

        .date {
          width: 88px;
          color: $cBgPrimary;
          margin-left: 25px;
          margin-right: 30px;
          font-family: Noto Sans JP;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
          /* 150% */
          letter-spacing: 1.4px;
        }

        a {
          text-decoration: none;
        }

        .title {
          width: 692px;
          margin: 10px;
          flex: 1;
          color: $cBgPrimary;
          font-family: Noto Sans JP;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 21px;
          /* 150% */
          letter-spacing: 1.4px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          @include sp {
            flex: none;
            width: 100%;
            margin-top: 17px;
          }
        }

        .title:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .lpContainer {
    width: 100%;
  }

  //タイトルイメージ
  .headerContainer {
    width: 100%;
    aspect-ratio: 986/1500;
    max-height: 1500px;
    overflow: hidden;

    .headerContent {
      display: flex;
      justify-content: center;
      width: 100%;
      aspect-ratio: 986/1500;
      background: #5847bd;

      .bgImgContent {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        max-width: 768px;
        min-width: 0px;
        aspect-ratio: 986/1500;

        max-height: 100%;

        .headerBackGroundImg {
          position: absolute;
          max-height: 1500px;
          height: 1500px;
        }

        .gradiant {
          position: absolute;
          width: 100%;
          background-image: radial-gradient(
            circle,
            transparent 0%,
            transparent 40%,
            #5847bd 60%
          );
        }

        .overlayImg {
          position: absolute;
          background-image: url("../../assets/images/2x/dot.png");
          width: 100%;
          height: 100%;
        }

        .pcImg {
          position: absolute;
          max-width: 100%;
          height: 100%;
        }

        .titleWrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .spTitle {
            position: absolute;
            width: 80%;
            height: auto;
            flex-shrink: 0;
            top: 43%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .pcLogoWrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          .pcLogo {
            position: relative;
            top: 0;
            left: 0;
            width: 28%;
            height: auto;
            flex-shrink: 0;
          }
        }

        .startButtonWrapper {
          margin-top: 29px;
          position: absolute;
          display: flex;
          justify-content: center;
          width: 100%;
          top: 70%;
          background-color: transparent;

          .startButton {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 565px;
            max-height: 105px;
            width: 57.3%;
            height: 10.6vw;
            flex-shrink: 0;
            border-radius: 1.2vw;
            background: #ec5e3f;
            color: var(--TxBody, #fff);
            text-align: center;
            font-family: "Noto Sans JP";
            font-size: 3.1vw;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            /* 187.5% */
            letter-spacing: 1.6px;
            z-index: 1;
          }

          .startButtonBg {
            position: absolute;
            max-width: 565px;
            max-height: 105px;
            width: 57.3%;
            height: 10.6vw;
            flex-shrink: 0;
            border-radius: 1.2vw;
            background: #ffffff;
          }
        }
      }
    }
  }

  .scrollDown {
    position: absolute;
    width: auto;
    height: 12px;
    text-align: center;

    .rectangle {
      height: 35px;
    }

    .scrollText {
      position: relative;
      width: 177px;
      height: 15px;
      font-size: 11px;
      line-height: 55px;
      /* 504.545% */
      letter-spacing: 1.1px;
    }
  }

  //about
  .aboutContainer {
    position: relative;
    display: inline-block;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    overflow: hidden;
    background-color: #23253e;

    .aboutBg {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 1861px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;

      .aboutBgColor {
        position: absolute;
        width: 100%;
        height: auto;
        max-height: 1571px;
        flex-shrink: 0;
        aspect-ratio: auto;
      }

      .aboutBgImg {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100%;
        width: 500px;
        min-height: 696px;
        height: 750px;
        max-height: 1500px;
        flex-shrink: 0;
        opacity: 0.8;
      }
    }

    .aboutContentWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      text-align: center;

      .aboutHeaderWrapper {
        margin-top: 60px;
        gap: 0px;

        .headerTitle {
          width: 113px;
          height: auto;
          color: var(--TxBody, #fff);
          font-family: Saira;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 15px;
          letter-spacing: 3px;
          text-transform: uppercase;
        }

        .headerSubTitle {
          display: block;
          height: auto;
          color: var(--TxBody, #fff);
          text-align: center;
          font-family: "Noto Sans JP";
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 3px;
        }
      }
    }

    .image-container {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 13px;
      align-items: flex-end;
      width: auto;
      height: auto;
      padding: 0 20px;

      .aboutImgWrapper {
        position: relative;
        width: 100%;
        max-height: 414px;
        height: auto;
        flex-shrink: 0;

        .aboutScreenMask {
          position: absolute;
          max-width: 670px;
          max-height: 274.281px;
          width: 100%;
          height: auto;
          flex-shrink: 0;
          top: 0;
          left: 0;
        }

        .aboutScreenImg {
          position: relative;
          max-width: 760px;
          max-height: 414px;
          width: 100%;
          height: auto;
          flex-shrink: 0;
          top: 0;
          left: 0;
        }
      }
    }

    .textContainer {
      margin-top: 0;
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      align-items: center;
      gap: 0;
      padding: 0 20px;
      margin-bottom: 80px;

      .aboutContentTitle {
        width: 100%;
        margin-top: 23px;
        margin-bottom: 18px;
        color: var(--BgWhite, #fff);
        font-family: "Noto Sans JP";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
        /* 155% */
        letter-spacing: 2px;
      }

      .aboutContentDescriptionWrapper {
        width: 100%;
        margin-top: 0;
        height: auto;
        display: flex;
        justify-content: center;

        .aboutContentDescription {
          width: 100%;
          color: var(--TxBody, #fff);
          font-family: "Noto Sans JP";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          /* 185.714% */
          letter-spacing: 1.3px;
        }
      }
    }
  }

  //FEATURES
  .featuresContainer {
    width: 100%;

    .featuresContentWrapper {
      width: auto;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 80px;
      padding: 0 20px;

      .featuresHeaderWrapper {
        margin-top: 60px;
        width: 100%;

        .featuresHeaderTitle {
          width: 100%;
          color: $cBgPrimary;
          font-family: Saira;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 15px;
          /* 555% */
          letter-spacing: 2px;
          text-transform: uppercase;
        }

        .featuresHeaderSubTitle {
          margin-top: 7px;
          margin-bottom: 50px;
          color: $cTxAccent;
          text-align: center;
          font-family: "Noto Sans JP";
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          /* 88% */
          letter-spacing: 2.5px;
        }
      }

      .featuresContent {
        margin-top: 0;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 55px;

        .featuresTextWrapper {
          width: 100%;
          text-align: left;
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        .featuresTextTitle {
          width: 100%;
          height: auto;
          color: $cTxAccent;
          font-family: "Noto Sans JP";
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: 35px;
          /* 152.778% */
          letter-spacing: 2.5px;
        }

        .featuresTextDescription {
          width: 100%;
          height: auto;
          margin: 0;
          font-family: "Noto Sans JP";
          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: 23px;
          /* 173.077% */
          letter-spacing: 1.3px;
        }

        .featuresWrapper1 {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: auto;

          .contentWrapperType1 {
            display: inline-flex;
            flex-direction: column-reverse;
            align-items: center;
            gap: 31px;
            width: 100%;
            height: auto;

            .featuresImg_01 {
              width: 100%;
              height: auto;
            }

            .featuresTextWrapper1 {
              @extend .featuresTextWrapper;

              .featuresTextTitle1 {
                @extend .featuresTextTitle;
              }

              .featuresTextDescription1 {
                @extend .featuresTextDescription;
              }
            }
          }
        }

        .featuresWrapper2 {
          width: 100%;
          height: auto;

          .contentWrapperType2 {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            width: 100%;
            height: auto;

            .featuresTextWrapper2 {
              @extend .featuresTextWrapper;

              .featuresTextTitle2 {
                @extend .featuresTextTitle;
              }

              .featuresTextDescription2 {
                @extend .featuresTextDescription;
              }
            }

            .features2ImgWrapper {
              width: auto;
              height: auto;
              position: relative;

              .featuresImg_02 {
                width: 100%;
                height: auto;
              }
            }
          }
        }

        .featuresWrapper3 {
          width: 100%;
          height: auto;

          .contentWrapperType1 {
            width: 100%;
            height: auto;
            display: inline-flex;
            flex-direction: column-reverse;
            align-items: center;
            gap: 26px;

            .featuresTextWrapper3 {
              @extend .featuresTextWrapper;

              .featuresTextTitle3 {
                @extend .featuresTextTitle;
              }

              .featuresTextDescription3 {
                @extend .featuresTextDescription;
              }
            }

            .features3ImgWrapper {
              width: auto;
              height: auto;

              .featuresImg_03 {
                width: 100%;
                height: auto;
              }
            }
          }
        }

        .featuresWrapper4 {
          width: 100%;
          height: auto;

          .featuresWrapper4_content {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            gap: 29px;
            width: auto;

            .featuresTextWrapper4 {
              @extend .featuresTextWrapper;

              .featuresTextTitle4 {
                @extend .featuresTextTitle;
              }

              .featuresTextDescription4 {
                @extend .featuresTextDescription;
              }
            }

            .features4ImgWrapper {
              width: auto;
              height: auto;

              .featuresImg_04 {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  .newsContainer {
    width: 100%;

    .newsContent {
      width: 90%;
      margin: 50px auto;

      .head {
        .logo {
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 2.5px;
          margin-bottom: 9px;
        }

        .info {
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          letter-spacing: 1.1px;
          margin-bottom: 24px;
        }
      }

      .newsPosts {
        .news {
          width: 100%;
          padding: 17px 0;
          padding: 17px 0 13px 0;
          margin-bottom: 1px;

          .type {
            width: 75px;
            height: 18px;
            border-radius: 2.5px;
          }

          .date {
            margin-left: 15px;
            font-size: 12px;
          }

          .title {
            width: auto;
            padding: 0;
            margin-top: 9px;
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 1.2px;
            text-decoration: underline;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }

  .startContainer {
    width: 100%;
    height: auto;
    margin-top: 0;

    .startBgWrapper {
      width: 100%;
      height: auto;

      .startBg {
        position: relative;
        width: 100%;
        height: auto;
        min-height: 130px;
        max-height: 260px;
      }
      .startImg {
        min-width: 100%;
        width: 100%;
        height: auto;
        min-height: 130px;
        max-height: 260px;
        position: relative;
        object-fit: cover;
      }
    }

    .bottomStartButtonWrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: transparent;

      .bottomStartButton {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        width: 66.65%;
        height: 10.5vw;
        flex-shrink: 0;
        border-radius: 1.2vw;
        background: #ec5e3f;
        color: var(--TxBody, #fff);
        text-align: center;
        font-family: "Noto Sans JP";
        font-size: 3.2vw;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        /* 187.5% */
        letter-spacing: 1.1px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }

      .bottomStartButtonBg {
        position: absolute;
        max-width: 500px;
        width: 66.65%;
        height: 40px;
        height: 10.5vw;
        flex-shrink: 0;
        border-radius: 1.2vw;
        background: #ffffff;
        z-index: 0;
      }
    }
  }

  .footerWrapper {
    height: auto;

    .footerLogo {
      margin-top: 63px;
      margin-bottom: 57px;
      width: 90px;
      height: auto;
    }

    .footerLinkWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
      gap: 15px;

      .footerLink {
        color: #ffffff;
        font-family: "Noto Sans JP";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.3px;
      }
    }

    .logo_x {
      width: 25px;
      height: 25px;
      margin-top: 47px;
      margin-bottom: 0;
    }

    .copyRightInfo {
      margin-top: 60px;
      margin-bottom: 63px;
      width: 335px;
      color: #ccc;
      font-family: "Noto Sans JP";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 180% */
      letter-spacing: 1px;
    }
  }
}

@media screen and (min-width: 1080px) and (max-width: 1399px) {
  .headerContainer {
    .headerContent {
      .bgImgContent {
        .startButtonWrapper {
          .startButton {
            width: 290px;
            height: 50px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1079px) {
  .lpContainer {
    overflow-x: scroll;
  }

  .newsContainer {
    width: 1000px;
    margin-left: 40px;

    .newsContent {
      width: 1079px;
    }
  }

  .featuresContainer {
    width: 1079px;
  }

  .headerContainer {
    width: 1079px;
    height: 542px;

    .headerContent {
      display: flex;

      .bgImgContent {
        height: 542px;

        .headerBackgroundImg {
          max-height: 542px;
          width: auto;
          height: auto;
          overflow-x: hidden;
        }

        .startButtonWrapper {
          .startButton {
            width: 290px;
            height: 50px;
          }
        }
      }
    }
  }

  .aboutContainer {
    width: 1079px;
  }

  .startContainer {
    width: 1079px;
  }

  .shareWrapper {
    width: 1079px;
  }

  .footerWrapper {
    width: 1079px;
  }
}
