@import "src/assets/styles/_variables.module.scss";

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalMask {
  inset: 0;
  width: 100vw;
  min-width: 960px;
  min-height: 960px;
  height: 100vh;
  background-color: #23253ee5;
  pointer-events: none;
  position: absolute;
}
