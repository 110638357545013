@import "src/assets/styles/_variables.module.scss";

.checkboxSwitch {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 138px;
  height: 38px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  line-height: 1.2;
  font-size: 14px;
  border: 1px solid $cBgWhite;

  .checkboxAnimate {
    position: relative;
    display: block;
    width: 138px;
    height: 38px;
    -webkit-transition: background 0.25s ease-out 0s;
    transition: background 0.25s ease-out 0s;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 32px;
      height: 32px;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      background-color: $cBgWhite;
      top: 3px;
      left: 3px;
      -webkit-transition: left 0.3s ease-out 0s;
      transition: left 0.3s ease-out 0s;
    }
  }

  input.inputCheckbox:checked + .checkboxAnimate {
    background-color: $cBgSecondary;

    &:before {
      left: 103px;
      background-color: $cBgWhite;
    }
  }

  .checkboxOff,
  .checkboxOn {
    float: right;
    color: $cBgWhite;
    @include jpParagraph02;
    padding-top: 6px;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }

  .checkboxOff {
    margin-right: 30px;
    opacity: 1;
  }

  .checkboxOn {
    display: none;
    float: left;
    margin-left: 30px;
    opacity: 0;
  }

  input.inputCheckbox:checked + .checkboxAnimate {
    .checkboxOff {
      display: none;
      opacity: 0;
    }

    .checkboxOn {
      display: block;
      opacity: 1;
    }
  }
}
