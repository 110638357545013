@import "src/assets/styles/_variables.module.scss";

.root {
  display: flex;
  justify-content: center;
  padding-top: 130px;
}

.inner {
  width: 100%;
  width: 1320px;

  @media only screen and (max-width: 1559px) {
    width: 1110px;
  }

  @media only screen and (max-width: 1349px) {
    width: 860px;
  }
}

.contents {
  width: auto;
  height: 794px;
  display: inline-flex;
}
.navigation {
  width: 355px;
  height: 100%;
  padding: 40px 0;
  margin: 0;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  list-style: none;
  background-color: rgba(56, 51, 102, 0.9);
  border-radius: 10px 0 0 10px;
  box-sizing: border-box;

  @media only screen and (max-width: 1349px) {
    width: 190px;
  }
}
.body {
  width: 965px;
  max-width: 1320px;
  height: 100%;
  background-color: rgba(35, 37, 62, 0.9);
  border-radius: 0 10px 10px 0;
  overflow: hidden;

  @media only screen and (max-width: 1559px) {
    width: 755px;
  }

  @media only screen and (max-width: 1349px) {
    width: 670px;
  }
}

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(35, 37, 62, 0.9);
  z-index: 2;
}

.signOutModal {
  width: 552px;
}

.signOutModalContent {
  padding: 40px 0;
  text-align: center;
}

.signOutModalTitle {
  margin-bottom: 40px;
  @include jpHeadline01;
  color: $cTxBody;
}

.signOutModalText {
  @include jpHeadline02;
  color: $cTxBody;
}

.signOutModalButton {
  width: 100%;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-top: 1px solid $cBgSecondary;
}
