.small {
  border: none;
  min-width: 138px;
  height: 30px;
  color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);

  div {
    font-size: 22px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.2);
    cursor: default;
    color: #ffffff;
  }
}

.large {
  border: none;
  color: #ffffff;
  min-width: 286px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);

  div {
    font-size: 30px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.2);
    cursor: default;
    color: #ffffff;
  }
}

.tiny {
  border: none;
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);

  div {
    font-size: 22px;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.2);
    cursor: default;
    color: #ffffff;
  }
}
