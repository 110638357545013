@import "src/assets/styles/_variables.module.scss";

.goTopButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .arrowsUpIcon {
    width: 50px;
    height: 50px;
    padding: 8px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .goTop {
    @include jpMININotoSans;
    color: #ffffff;
  }
}
