@import "src/assets/styles/_variables.module.scss";

.newsContentWrapper {
  @include hideScrollBar;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: scroll;

  @media (max-width: 1365px) {
    min-width: 640px;
  }

  .newsContentContainer {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px 120px;
  }

  .newsContents {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    gap: 10px;
  }

  .articleWrapper {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    max-width: 700px;
    width: 700px;
    height: 100px;
    background: #191a2db2;
    border-radius: 10px;
    padding: 30px 20px;
    gap: 23px;
    color: #ffffff;

    .articleLink {
      text-decoration: none;
    }

    .typeIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 30px;
      background: #ffffff;
      color: black;
      border-radius: 10px;
      @include jpParagraph03;

      span {
        color: $cTxBody;
        font-family: $fSaira;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        /* 150% */
        letter-spacing: 1.2px;
      }
    }

    .newsDate {
      @include fNotoSansJP18;
    }

    .textLink {
      text-decoration: none;
      &:hover {
        color: #ffffff;
        text-decoration: underline;
      }
    }

    .newsTitle {
      @include fBoldNotoSansJP2;
      color: #ffffff;
      width: 380px;
      max-height: 54px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .detailButton {
      margin-left: 9px;
      width: 30px;
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
