@import "src/assets/styles/_variables.module.scss";

.modalWrapper {
  width: 260px;
  height: 261px;
  left: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  position: absolute;
  left: 550px;
  bottom: 144px;
  z-index: 1;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 100%;
  //   left: 50%;
  //   margin-left: -15px;
  //   border: 15px solid transparent;
  //   border-top: 15px solid #ffffff;
  // }

  .micSettings {
    height: 90px;
    border-bottom: solid 1px #383366;
  }

  .speakerSettings {
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .name {
    //styleName: 日本語本文03;
    font-family: Noto Sans JP;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #533eae;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .devices {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    select {
      appearance: none;
      /*  safari  */
      -webkit-appearance: none;
      /*  other styles for aesthetics */
      background-color: #383366;
      width: 220px;
      height: 40px;
      border-radius: 10px;
      //styleName: 日本語本文05;
      font-family: Noto Sans JP;
      font-size: 10px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0.1em;
      text-align: left;
      color: #ffffff;
      padding-left: 20px;
      padding-right: 40px;
      cursor: pointer;
      text-overflow: ellipsis;
    }

    &::after {
      --size: 5px;
      content: "";
      position: absolute;
      right: 25px;
      pointer-events: none;
      margin-right: 10px;
      border-left: var(--size) solid transparent;
      border-right: var(--size) solid transparent;
      border-top: var(--size) solid white;
    }
  }
  .slideContainer {
    margin-top: 10px;
    width: 200px;
    height: 30px;
    background-color: #d9d9d9;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;

    .slider {
      width: 100%;
      height: 4px;
      border-radius: 2px;
      accent-color: #533eae;
    }
  }
}
