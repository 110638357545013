@import "src/assets/styles/_variables.module.scss";

.joinButton {
  /* 日本語見出し02 */
  @include jpParagraph02;
  color: $cTxBody;
  text-align: center;

  border: none;
  width: 230px;
  height: 72px;
  border-radius: 40px;
  background: $cBgAccent;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    opacity: 0.2;
    background: $cBgAccent;
  }
  &:disabled {
    background: #b8b8b8;
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
    cursor: default;
  }
  &.isLoading {
    pointer-events: none;
    cursor: default;
    background: $cBgSecondary;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .rotate {
      animation: rotate-anime 0.8s linear infinite;
      transform-origin: center;
    }

    @keyframes rotate-anime {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    .content {
      @include jpParagraph02;
    }
  }
}
