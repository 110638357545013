@import "src/assets/styles/_variables.module.scss";

.roomContainer {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 760px;
  min-height: 800px;
  background-color: #191a2dcc;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px 25px;

  .modalWrapper {
    width: 100vw;
    height: 100vh;
    min-width: 960px;
    min-height: 960px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;

    .modalContainer {
      z-index: 3;
    }

    .modalMask {
      inset: 0;
      width: 100vw;
      min-width: 960px;
      min-height: 960px;
      height: 100vh;
      background-color: #23253ee5;
      pointer-events: none;
      position: fixed;
      z-index: 3;
    }
  }

  .roomMenu {
    width: 100%;
    display: flex;
    height: 50px;

    .chevronRight {
      font-size: 30px;
      color: #ffffff;
      margin-left: 10px;
    }

    .lobbyMenu {
      padding-left: 20px;
      width: 50%;
      background-color: #533eae;
      display: flex;
      flex-direction: row;
      border-radius: 5px 0px 0px 5px;
      cursor: pointer;
      justify-content: center;
      align-items: center;
    }

    .lobbyMenuShowMap {
      background-color: #533eae66;
    }

    .mapMenu {
      padding-right: 20px;
      width: 50%;
      display: flex;
      border-radius: 0px 5px 5px 0px;
      background-color: #533eae66;
      cursor: pointer;
      justify-content: center;
      align-items: center;
    }

    .mapMenuShowMap {
      background-color: #533eae;
    }
  }
}

.menuName {
  @include enMenu03;
  text-align: left;
  align-self: center;
  color: #ffffff;
}

.calendar {
  font-size: 30px;
  color: #533eae;
  border: none;
}

.roomCode {
  color: #533eae;
  border: none;
  width: 30px;
  height: 30px;

  &svg path {
    fill: #000000;
  }
}

.roomInfo {
  font-size: 30px;
  color: #533eae;
  border: none;
}

.buttonBackGround {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 25px;
  cursor: pointer;
}

.unassigned {
  margin-top: 20px;
  height: 130px;
  border-radius: 5px;
  border: 1px solid #64667d;
  background-color: rgba(35, 37, 62, 0.8);

  .label {
    position: relative;
    min-width: 64px;
    max-width: 64px;
    min-height: 64px;
    max-height: 64px;
    top: 4px;
    left: 4px;
    border: 8px;
    border-style: inset;
    border-top: 8px solid;
    border-left: 8px solid;
    border-color: #64667d;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }

  .title {
    position: relative;
    top: -74px;
    min-width: 100%;
    height: 38px;
    background-color: #64667d66;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .login {
    font-size: 30px;
    align-self: center;
    color: #ffffff;
  }

  .members {
    @include scrollBar;
    height: 58px;
    max-height: 58px;
    min-width: 690px;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    top: -58px;
    padding-left: 20px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, 220px);
    grid-auto-rows: 24px;
    cursor: pointer;

    .name {
      @include jpNotoSans2;
      max-width: 192px;
      color: #ffffff;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .userName {
      @include jpParagraph03;
      max-width: 192px;
      text-align: left;
      color: $cBgAccent;
      background-color: $cBgWhite;
    }
  }
}

.left {
  @include fNotoSansJP18;
  text-align: left;
  color: #ffffff;
  margin-left: 20px;
}

.right {
  margin-right: 4px;
  display: flex;
}

.number {
  @include fNotoSansJP18;
  color: #ffffff;
  text-align: right;
  margin-right: 10px;
  align-self: end;
}

.teams {
  @include hideScrollBar;
  margin-top: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(230px, auto));
  grid-template-rows: repeat(auto-fit, minmax(auto, 132px));
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-height: 960px) {
    height: 610px;
  }

  .team {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 230px;
    height: 130px;
    border-radius: 5px;
    max-height: 130px;
    background-color: #23253ecc;
    border: 1px solid;

    .label {
      position: relative;
      min-width: 64px;
      max-width: 64px;
      min-height: 64px;
      max-height: 64px;
      top: 4px;
      left: 4px;
      border: 8px;
      border-style: inset;
      border-top: 8px solid;
      border-left: 8px solid;
      border-right: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }

    .title {
      position: relative;
      top: -74px;
      width: 230px;
      height: 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .loginout {
      font-size: 30px;
      align-self: center;
      cursor: pointer;
      color: #ffffff;
    }

    .members {
      position: relative;
      top: -70px;
      padding-left: 20px;
      display: grid;
      grid-auto-rows: 24px;
      cursor: pointer;
      height: 72px;

      .name {
        @include jpNotoSans2;
        max-width: 192px;
        text-align: left;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .userName {
        @include jpParagraph03;
        max-width: 192px;
        text-align: left;
        color: $cTxAccent;
        background-color: $cBgWhite;
      }
    }
  }

  .joined {
    background-color: rgba(73, 47, 181, 0.5);
  }
}

.mapContainer {
  margin-top: 20px;
  border-radius: 5px;
  background-color: #ffffff1a;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1466px) {
    width: 772px;
    height: 772px;
  }
}

.balloon {
  position: absolute;
  display: flex;
  margin: 1.5em 0;
  padding: 7px 10px;
  width: 295px;
  font-size: 16px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 160px;
  margin-left: -100px;
  align-items: center;
  z-index: 1;
}

.balloon:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: 25px;
  border: 8px solid transparent;
  border-bottom: 15px solid #ffffff;
}

.customCodeButton {
  cursor: pointer;
  color: #533eae;
  font-size: 20px;
  margin-left: 10px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

.disabled {
  opacity: 0.2;
}
