.roomBackGroundContainer {
  // -moz-box-sizing: border-box;
  // box-sizing: border-box;
  // padding-top: 80px;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -1;
  min-width: 960px;
  min-height: 960px;
}

.backGroundShadow {
  background-color: #23253ee5;
  opacity: 0.9;
  width: 100vw;
  height: 100vh;
  position: absolute;
  min-width: 960px;
  min-height: 960px;
}

.backGroundImage {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -1;
  background: lightgray 50% / cover no-repeat;
  backdrop-filter: blur(3px);
  min-width: 960px;
  min-height: 960px;
}
