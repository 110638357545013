.layOutContainer {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  min-height: 960px;
  min-width: 910px;
  padding-top: 80px;
}

@media (max-width: 1365px) {
  .chatContents {
    width: 10vw;
    height: 100%;
    min-width: 170px;
  }
}

.leftContents {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 30vw;
  height: 100%;
  min-width: 580px;
  padding: 50px 40px 0px 100px;
}

@media (max-width: 1365px) {
  .leftContents {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100vw;
    height: calc(100% - 80px);
    min-width: 580px;
    position: absolute;
    z-index: 2;
  }

  .slidein {
    animation: slideinLeft 0.8s;

    @keyframes slideinLeft {
      0% {
        transform: translateX(-100vw);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  .slideout {
    animation: slideoutLeft 0.8s;

    @keyframes slideoutLeft {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100vw);
      }
    }
  }
}

.rightContents {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 70vw;
  height: 100%;
  min-width: 780px;
  padding-top: 50px;
  padding-right: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (max-width: 1365px) {
  .rightContents {
    width: 90vw;
    padding-right: 20px;
  }
}

.chatButton {
  cursor: pointer;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #533eae;
  position: absolute;
  left: 40px;
  bottom: 55px;

  @media (max-height: 960px) {
    position: relative;
    top: 825px;
  }
}

.chatIcon {
  color: #ffffff;
  font-size: 50px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

.hideChatBackGround {
  position: absolute;
  width: 100vw;
  height: calc(100% - 80px);
  background-color: #23253ee5;
  z-index: 1;
  min-width: 960px;
  min-height: 960px;
}

.hideBack {
  display: none;
}
