@import "src/assets/styles/_variables.module.scss";

.root {
  width: 100%;
  height: 100%;
  padding: 80px 0;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.table {
  width: 610px;
  height: auto;

  tr {
    width: 100%;
    height: auto;
  }

  th,
  td {
    padding: 0;
    text-align: left;
    color: $cTxBody;
    &:first-child::before {
      content: "";
      vertical-align: middle;
      display: inline-block;
      min-height: 40px;
    }
  }

  th {
    width: 28%;
    height: auto;

    @include jpParagraph03;
  }

  td {
    width: 72%;
    height: auto;
  }
}

.delateButton {
  padding: 0;
  border: none;
  background-color: transparent;
  color: $cTxBody;
  cursor: pointer;
  text-decoration: underline;
  line-height: 1;
  @include jpParagraph04;
}

.modal {
  width: 552px;
}

.content {
  padding: 40px 0;
  text-align: center;
}

.title {
  margin-bottom: 40px;
  @include jpHeadline01;
  color: $cTxBody;
}

.text {
  @include jpHeadline02;
  color: $cTxBody;
}

.button {
  width: 100%;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-top: 1px solid $cBgSecondary;
}

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(35, 37, 62, 0.9);
  z-index: 2;
}
