@import "src/assets/styles/_variables.module.scss";

.InfoHeaderWrapper {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  background: $cBgPrimary;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;

  .headerContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .backButtonWrapper {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: left;

      .link {
        display: flex;
        text-decoration: none;
        cursor: pointer;
        margin-top: 10px;
        margin-left: 10px;

        .arrowsLeft {
          width: 30px;
          height: 30px;
        }

        .moveToBackText {
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #ffffff;
          font-family: Saira;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          /* 200% */
          letter-spacing: 1.2px;
        }
      }
    }

    .header {
      width: 100%;
      display: flex;
      align-items: center;

      .title {
        @include enHeadline01;
        color: $cTxBody;
        margin-left: 15.625vw;
        margin-right: 40px;
      }

      .subTitle {
        @include jpHeadline01;
        color: $cTxBody;
      }
    }
  }
}
