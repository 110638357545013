@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Saira");

//////////////////////////
// Font Family
/////////////////////////

// JP
$fNotoSansJP: Noto Sans JP;

// EN
$fSaira: Saira;

//////////////////////////
// TYPOGRAPHY
/////////////////////////

// JP
@mixin fNotoSansJP18 {
  font-family: $fNotoSansJP;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.1em;
}
@mixin fNotoSansJP24 {
  font-family: $fNotoSansJP;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.1em;
  font-style: normal;
}
@mixin fNotoSansJP15 {
  font-family: $fNotoSansJP;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.1em;
  font-style: normal;
}
@mixin fBoldNotoSansJP {
  font-family: $fNotoSansJP;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.4px;
}
@mixin fMediumNotoSansJP {
  font-family: $fNotoSansJP;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 2.4px;
}
@mixin fRegularNotoSansJP {
  font-family: $fNotoSansJP;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.4px;
}
@mixin fRegularNotoSansJP400 {
  font-family: $fNotoSansJP;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1em;
}
@mixin jpHeadline01 {
  /* 日本語見出し01 */
  font-family: $fNotoSansJP;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 150% */
  letter-spacing: 2.4px;
}
@mixin jpHeadline02 {
  /* 日本語見出し02 */
  font-family: $fNotoSansJP;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  letter-spacing: 2px;
}
@mixin jpHeadline03 {
  /* 日本語見出し03 */
  font-family: $fNotoSansJP;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.1em;
}
@mixin jpParagraph01 {
  /* 日本語本文01 */
  font-family: $fNotoSansJP;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 150% */
  letter-spacing: 1.8px;
}
@mixin jpParagraph02 {
  /* 日本語本文02 */
  font-family: $fNotoSansJP;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 1.6px;
}
@mixin jpParagraph03 {
  /* 日本語本文03 */
  font-family: $fNotoSansJP;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
  letter-spacing: 1.4px;
}
@mixin jpParagraph04 {
  /* 日本語本文04 */
  font-family: $fNotoSansJP;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 1.2px;
}
@mixin jpParagraph05 {
  /* 日本語本文05 */
  font-family: $fNotoSansJP;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 150% */
  letter-spacing: 1px;
}
@mixin jpParagraph06 {
  /* 日本語本文05 */
  font-family: $fNotoSansJP;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
  letter-spacing: 0.1em;
}
@mixin jpParagraph07 {
  /* 日本語本文05 */
  font-family: $fNotoSansJP;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 150% */
  letter-spacing: 0.1em;
}
@mixin fBoldNotoSansJP2 {
  /* 日本語本文05 */
  font-family: $fNotoSansJP;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px; /* 150% */
  letter-spacing: 0.1em;
}
@mixin jpNotoSans {
  font-family: $fNotoSansJP;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 150% */
  letter-spacing: 1px;
}
@mixin jpMININotoSans {
  font-family: $fNotoSansJP;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 150% */
  letter-spacing: 1px;
}
@mixin jpNotoSans2 {
  font-family: $fNotoSansJP;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 1px;
}
@mixin jpNotoSans3 {
  font-family: $fNotoSansJP;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.1em;
}

// EN
@mixin fSairaJP20 {
  font-family: $fSaira;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1em;
  font-style: normal;
}
@mixin fMiniBoldSaira {
  font-family: $fSaira;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.1em;
}
@mixin fBoldSaira {
  font-family: $fSaira;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.4px;
}
@mixin fSemiBoldSaira {
  font-family: $fSaira;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.4px;
}
@mixin fRegularSaira {
  font-family: $fSaira;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.4px;
}
@mixin enHeadline01 {
  /* Headline01 */
  font-family: $fSaira;
  font-size: 74px;
  font-style: normal;
  font-weight: 700;
  line-height: 111px; /* 150% */
}
@mixin enHeadline02 {
  /* Headline02 */
  font-family: $fSaira;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 150% */
}
@mixin enHeadline03 {
  /* Headline03 */
  font-family: $fSaira;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* 150% */
}
@mixin enParagraph01 {
  /* Paragraph01 */
  font-family: $fSaira;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
  letter-spacing: 2.4px;
}
@mixin enParagraph02 {
  /* Paragraph02 */
  font-family: $fSaira;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px; /* 150% */
  letter-spacing: 1.8px;
}
@mixin enParagraph03 {
  /* Paragraph03 */
  font-family: $fSaira;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 1.2px;
}
@mixin enMenu01 {
  /* enMenu01 */
  font-family: $fSaira;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 150% */
  letter-spacing: 1.2px;
}
@mixin enMenu02 {
  /* enMenu01 */
  font-family: $fSaira;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 150% */
  letter-spacing: 1.2px;
}
@mixin enMenu03 {
  /* enMenu01 */
  font-family: $fSaira;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 150% */
  letter-spacing: 0.1em;
}

//////////////////////////
// COLOR
/////////////////////////

// BACKGROUND
$cBgPrimary: #23253e;
$cBgSecondary: #383366;
$cBgTertiary: #45407c;
$cBgAccent: #533eae;
$cBgAlert: #c34343;
$cBgWhite: #ffffff;

// TEXT
$cTxBody: #ffffff;
$cTxPlaceHolder: rgba(255, 255, 255, 0.3);
$cTxAccent: #533eae;
$cTxAlert: #ff9b9b;

//ScrollBar
@mixin scrollBar {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffffff80;
    border-radius: 4px;
  }
}

@mixin hideScrollBar {
  /* for Internet Explorer, Edge */
  -ms-overflow-style: none;
  /* for Firefox */
  scrollbar-width: none;
  /* for Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
