@import "src/assets/styles/_variables.module.scss";

.inputTextContainer{
  display: flex;
  align-items: center;
}

.inputText {
  border: unset;
  box-sizing: border-box;
  width: 100%;
  min-width: 360px;
  height: 40px;
  padding-left: 20px;
  padding-right: 40px;
  background-color: #FFFFFF66;
  border-radius: 10px;
  color: #ffffff;
  outline: none;
  left: 12px;
  position: relative;
  @include jpParagraph02;

  &:focus {
    border: 1px solid $cBgWhite;
  }
  &:disabled {
    background: rgba($color: #ffffff, $alpha: 0.1);
    cursor: default;
  }
  &::placeholder{
    color: #FFFFFF4D;
    @include jpParagraph02;
  }
}

.send {
  position: relative;
  right: 20px;
  color: #ffffff;
  cursor: pointer;
}

.error {
  border: 1px solid $cTxAlert;

  &:focus {
    border-color: $cTxAlert;
  }
}
