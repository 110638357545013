@import "src/assets/styles/_variables.module.scss";

.modalWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-width: 836px;
  min-height: 960px;
  height: 100vh;
  top: 0;
  left: 0;

  @media (max-width: 1365px) {
    width: 100vw;
    min-width: 960px;
    min-height: 960px;
  }

  .header {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modalMask {
    inset: 0;
    width: 100vw;
    min-width: 836px;
    min-height: 960px;
    height: 100vh;
    background-color: #23253ee5;
    pointer-events: none;
    justify-self: flex-end;

    @media (max-width: 1365px) {
      width: 100vw;
      min-width: 960px;
      min-height: 960px;
    }
  }

  .modalContainer {
    @include hideScrollBar;
    max-width: 620px;
    max-height: 720px;
    z-index: 1;
    background-color: #45407c;
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 10px;
    align-items: center;
    padding: 40px 48px 40px 48px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .warnModalContainer {
    width: 552px;
    height: auto;
    max-height: 322px;
    min-height: 272px;
    overflow: hidden;
  }

  .closeButton {
    cursor: pointer;
    color: #ffffff;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 40px;
  }

  .title {
    //styleName: 日本語見出し01;
    @include jpHeadline01;
  }

  .subTitle {
    @include jpParagraph03;
  }

  .resultSubTitle {
    @include jpParagraph05;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
  }

  .warnDescription {
    @include jpParagraph02;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 15px;
  }
}

.bottom {
  margin-top: 25px;
  min-width: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .roomInfoCopyToolTip {
    border-radius: 10px;
    border: 1px #ffffff solid;
    text-align: center;
    background-color: #533eaee5;
  }

  .iconWrapper {
    margin-top: 20px;

    .icon {
      display: flex;
      gap: 30px;

      .shareIcon {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.resultBottom {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backToResult {
  @include jpParagraph02;
  color: #ffffff;
  cursor: pointer;
  width: 200px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.previousPage {
  @include jpParagraph02;
  color: #ffffff;
  margin-top: 24px;
  cursor: pointer;
}

.contents {
  margin-top: 25px;
  min-width: 456px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .content {
    padding: 15px 0 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .separation {
    border: 0;
    border-top: 1px solid #383366;
    width: 716px;
    margin: initial;
    margin: 15px 0 15px -48px;
  }

  .inputName {
    display: flex;
    align-items: center;

    .name {
      //styleName: 日本語本文03;
      @include jpParagraph03;
      text-align: left;
      color: #ffffff;
    }
  }

  .inputStyle {
    width: 434px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    /* 行と行の間(縦方向)の余白 */
    column-gap: 10px;
    align-items: center;
  }

  .inputUrlStyle {
    width: 434px;
    align-items: center;
  }
}
