@import "src/assets/styles/_variables.module.scss";

.newsHeader {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  padding-top: 50px;
  padding-bottom: 20px;
  color: white;
  align-items: center;
  height: 121px;

  .title {
    @include enHeadline01;
  }

  .subTitle {
    @include fBoldNotoSansJP;
    margin-left: 40px;
  }
}
