@import "src/assets/styles/_variables.module.scss";

.modalWrapper {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .modalMask {
    position: fixed;
    inset: 0;
    height: 100%;
    background-color: #23253ee5;
    pointer-events: none;
  }

  .kickoutContainer {
    width: 700px;
    height: 700px;
    border-radius: 10px;
    background-size: cover;
    z-index: 1;
    background-color: #423d77;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    transition: height 0.3s linear;
  }

  .confirmContainer {
    width: 552px;
    height: 362px;
    border-radius: 10px;
    background-size: cover;
    z-index: 1;
    background-color: #423d77;
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    transition: height 0.3s linear;
  }

  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    color: white;
    cursor: pointer;
    font-size: 40px;
  }

  .header {
    margin-top: 40px;
    //styleName: 日本語見出し01;
    font-family: Noto Sans JP;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #ffffff;
  }

  .subHeader {
    width: 418px;
    margin-top: 30px;
    //styleName: 日本語本文05;
    font-family: Noto Sans JP;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #ffffff;
  }

  .contents {
    @include scrollBar;
    width: 480px;
    height: 360px;
    background-color: #383366;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 30px 60px 0 60px;
    border-radius: 10px;
    overflow-y: scroll;

    .unassigned {
      //styleName: 日本語本文03;
      font-family: Noto Sans JP;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.1em;
      text-align: left;
      color: #ffffff;
      margin-bottom: 16px;
    }

    .playerCheck {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      color: white;

      .checkBox {
        font-size: 30px;
        cursor: pointer;
      }

      .playerName {
        margin-left: 50px;
        //styleName: 日本語見出し01;
        font-family: Noto Sans JP;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.1em;
        text-align: left;
      }
    }
  }

  .cancel {
    width: 160px;
    height: 15px;
    //styleName: 日本語本文02;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-align: center;
    cursor: pointer;
    color: white;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .kickoutContents {
    @include hideScrollBar;
    height: 80px;
    color: white;
    //styleName: 日本語本文02;
    font-family: Noto Sans JP;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1em;
    overflow-y: scroll;
  }
}
