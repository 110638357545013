@import "src/assets/styles/_variables.module.scss";

.root {
  width: 100%;
  height: 100%;
  padding: 254px 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.image {
  width: 120px;
  height: 120px;
  display: block;
  margin-bottom: 40px;
}

.text {
  @include fBoldNotoSansJP2;
  color: $cTxBody;
}
